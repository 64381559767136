import { LOCAL_STORAGE } from '~constants'
import jwtDecode from 'jwt-decode'
import { refreshToken } from 'services/refreshToken'

interface ICognitoUserJWT {
  email: string
  exp: number
  family_name: string
  given_name: string
  sub: string
}

export const getCognitoUserSession = async () => {
  // To get a user's cognito session we will check if the access token exists and if it is expired.
  // If the token is expired, refresh it.
  try {
    const cognitoUserSession = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!cognitoUserSession) {
      return null
    }
    // At this point the user exists, but let's confirm they have an active id token.
    const { id, refresh } = JSON.parse(cognitoUserSession)
    const user = jwtDecode<ICognitoUserJWT>(id)
    const { exp, email, family_name, given_name, sub: userId } = user

    const expiresDate = new Date(exp * 1000)
    const now = new Date()

    // Set 2 hours before a potential clash to ensure we are calling refresh when the token id is still available.
    now.setHours(now.getHours() + 1)

    if (expiresDate.getTime() <= now.getTime()) {
      // Get the refresh token and make a request to auth the user again.
      try {
        const data = await refreshToken({ authorization: id, token: refresh, userId: user['cognito:username'] })

        // Set a user's token based on the authRes value
        localStorage.setItem(
          LOCAL_STORAGE.USER_KEY,
          JSON.stringify({
            id: data.id,
            refresh: refresh
          })
        )

        const { email, family_name, given_name, sub: userId } = jwtDecode<ICognitoUserJWT>(data.id)
        return { email, family_name, given_name, userId }
      } catch (e) {
        console.error(e)
      }
    } else {
      return { email, family_name, given_name, userId }
    }

    return null
  } catch (e) {
    console.error(e)
  }
}

export const getCognitoUserInfo = async () => {
  try {
    const cognitoUser = await getCognitoUserSession()

    if (cognitoUser === null || cognitoUser === undefined) {
      return null
    }

    const { email, family_name = 'Anonymous', given_name = 'Squirrel' } = cognitoUser
    const initials = `${given_name.charAt(0).toUpperCase()}${family_name.charAt(0).toUpperCase()}`
    return { initials, name: `${given_name} ${family_name}`, email }
  } catch (e) {
    console.error(e)
  }
}

export const getIdToken = (): string => {
  const cognitoUserSession = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

  if (!cognitoUserSession) {
    return ''
  }

  const { id } = JSON.parse(cognitoUserSession)

  return id
}
