import { useEffect, RefObject, useState } from 'react'

export interface IObserverOptions {
  root?: HTMLElement | null
  rootMargin?: string
  threshold?: number | Array<number>
}

interface IUseIsIntersectingProps {
  observerOptions?: IObserverOptions
  ref: RefObject<HTMLElement>
}

const defaultObserverOptions: IObserverOptions = {
  root: null,
  rootMargin: '0px',
  threshold: [0.001, 0.9]
}

export const useIsIntersecting = ({ ref, observerOptions = defaultObserverOptions }: IUseIsIntersectingProps) => {
  const [intersectEntry, setIsIntersectEntry] = useState<null | IntersectionObserverEntry>(null)

  const handleIntersect = (entries: Array<IntersectionObserverEntry>) => {
    const [entry] = entries
    setIsIntersectEntry(entry)
  }

  useEffect(() => {
    const observer: IntersectionObserver = new IntersectionObserver(handleIntersect, observerOptions)
    const current = ref.current

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        return observer.disconnect()
      }
    }
  }, [ref, JSON.stringify(observerOptions)])

  return intersectEntry
}
