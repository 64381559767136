export const UploadErrorIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect x="1" y="1" width="30" height="30" rx="15" fill="#FF4444" fillOpacity="0.2" />
      <rect x="1" y="1" width="30" height="30" rx="15" stroke="#FF4444" strokeWidth="2" />
      <path
        d="M15.2428 9.72H17.7628L17.4828 19.02H15.5228L15.2428 9.72ZM16.5028 20.44C17.3028 20.44 17.8828 21.04 17.8828 21.82C17.8828 22.6 17.3028 23.2 16.5028 23.2C15.7228 23.2 15.1228 22.6 15.1228 21.82C15.1228 21.04 15.7228 20.44 16.5028 20.44Z"
        fill="#FF4444"
      />
    </svg>
  )
}
