export const LogOutIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3846 16C1.00032 16 0.673475 15.8653 0.404075 15.5959C0.134692 15.3265 0 14.9997 0 14.6154V1.3846C0 1.00032 0.134692 0.673476 0.404075 0.404076C0.673475 0.134692 1.00032 0 1.3846 0H8.01345V0.769225H1.3846C1.23077 0.769225 1.08974 0.833325 0.961525 0.961525C0.833325 1.08974 0.769225 1.23077 0.769225 1.3846V14.6154C0.769225 14.7692 0.833325 14.9103 0.961525 15.0385C1.08974 15.1667 1.23077 15.2308 1.3846 15.2308H8.01345V16H1.3846ZM12.7654 11.2404L12.1904 10.7038L14.5096 8.38463H5.1923V7.61538H14.4981L12.1789 5.29615L12.7346 4.74038L16 8.01538L12.7654 11.2404Z"
        fill="white"
      />
    </svg>
  )
}
