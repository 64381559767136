import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FormProps } from '~types'
import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_LOCATION_FORM, ANALYTICS_LINK_MODULE_GLOBAL_NAV } from '~analyticsConstants'
import { ConditionalWrapper, Heading, Modal } from '~elements'
import { Col, Container, Row } from 'react-grid-system'
import styles from './Form.module.scss'
import { useWindowSize } from 'utils/hooks'
import Image from 'next/image'

const ReactFormstack = dynamic(() => import('@msgtechnology/react-formstack').then(module => module.default), { ssr: false })

interface FormstackData {
  id: string
}

const Form = ({
  autoComplete = true,
  formId,
  formSubmitHandler,
  inputBgColor = 'white',
  isChildOfModal = false,
  submitButtonBgColor = 'white',
  submitButtonText = 'Submit',
  subtitle,
  title
}: FormProps) => {
  const { asPath } = useRouter()
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  const modalIsOpenHandler = (state: boolean) => {
    setModalIsOpen(state)
  }

  const analyticsEvent = () => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), {
      linkId: formatAnalyticsText(submitButtonText),
      linkModule: ANALYTICS_LINK_MODULE_GLOBAL_NAV,
      linkLocation: ANALYTICS_LINK_LOCATION_FORM
    })
  }

  const afterSubmit = (data: FormstackData) => {
    if (data.id) {
      setIsSuccessful(true)
      modalIsOpenHandler(true)
      analyticsEvent()
    }
  }

  const formClassNames = `formstack formstack--input-bg-color-transparent formstack--submit-btn-bg-${submitButtonBgColor}`

  return (
    <>
      <ConditionalWrapper
        condition={!isChildOfModal}
        wrapper={children => (
          <div className={styles['form-block']} id="contact-form">
            <Container className="container">
              <Row>
                {/* @TODO: look into why we have to use 'xl' instead of 'lg' */}
                <Col xl={4}>
                  <Heading level={2} levelDisplay={isBelowBreakpointMd ? 1 : 'display'}>
                    {title}
                  </Heading>
                  {subtitle && (
                    <Heading level={3} levelDisplay={2}>
                      {subtitle}
                    </Heading>
                  )}
                </Col>
                <Col xl={5} offset={{ xl: 3 }}>
                  {children}
                </Col>
              </Row>
            </Container>
          </div>
        )}
      >
        <>
          <small>Fields with an * are required.</small>
          <ReactFormstack
            autoComplete={autoComplete}
            className={formClassNames}
            id={formId}
            onAfterSubmit={afterSubmit}
            shouldResetOnSubmit={true}
            submitButtonText={submitButtonText}
          />

          <Modal isOpen={modalIsOpen} handleModalClose={() => modalIsOpenHandler(false)}>
            <div className={styles['form-modal-wrapper']}>
              <div className={styles['form-success']}>
                <div className={'image-container'}>
                  <Image
                    className={styles['form-success__icon']}
                    src="/assets/icon-check-mark.png"
                    alt="Form Success"
                    width="0"
                    height="0"
                    sizes="100vw"
                    priority
                  />
                </div>
              </div>

              <Heading level={5}>Thank you! Your inquiry has been sent!</Heading>
            </div>
          </Modal>
        </>
      </ConditionalWrapper>
    </>
  )
}

export default Form
