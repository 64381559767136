import { PaginationProps } from '~types'
import styles from './Pagination.module.scss'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import Image from 'next/image'

const Pagination = ({ currentPage, onPageChange, totalPages, onNext, onPrevious }: PaginationProps) => {
  const [visiblePageNums, setVisiblePageNums] = useState<number[]>([])

  const pageNums = Array.from({ length: totalPages }, (_, i) => i + 1)

  useEffect(() => {
    //If there are more than 3 pages, we limit the number of page #s that are shown
    if (totalPages > 3) {
      let visiblePageSlice: number[] = []
      if (currentPage === 1) {
        visiblePageSlice = pageNums.slice(0, 4)
      } else {
        if (currentPage > 1 && currentPage <= 3) {
          //Prevent the change of visible page #s if the newly selected page is currently visible
          if (visiblePageNums.length === 3 && visiblePageNums.includes(currentPage)) {
            return
          }
          visiblePageSlice = pageNums.slice(0, 3)
        }
        if (currentPage > 3) {
          if (currentPage === pageNums[pageNums.length - 1]) {
            visiblePageSlice = pageNums.slice(currentPage - 4, currentPage)
          } else {
            //Prevent the change of visible page #s if the newly selected page is currently visible
            if (visiblePageNums.length === 3 && visiblePageNums.includes(currentPage)) {
              return
            }
            visiblePageSlice = pageNums.slice(currentPage - 3, currentPage)
          }
        }
      }
      setVisiblePageNums(visiblePageSlice)
    } else {
      //If there are 3 pages of results or less, display all page #s
      setVisiblePageNums(pageNums)
    }
  }, [currentPage])

  const PageLink = (pageNum: number) => {
    const isSelected = pageNum === currentPage
    const classNames = cx([styles['page-link'], styles[isSelected ? 'page-link--selected' : '']])
    return (
      <li key={pageNum}>
        <button className={classNames} onClick={onPageChange} value={pageNum}>
          {pageNum}
        </button>
      </li>
    )
  }

  return (
    <nav role="navigation" className={styles['pagination-container']}>
      <ul>
        {currentPage > 1 ? (
          <li>
            <button className={styles['page-link']} onClick={onPrevious}>
              <Image src="/assets/content/chevron_left_FILL0_wght200_GRAD200_opsz48.svg" alt="Previous" width="24" height="24" />
            </button>
          </li>
        ) : (
          ''
        )}
        {visiblePageNums.map(num => PageLink(num))}
        {currentPage < pageNums[pageNums.length - 1] ? (
          <li>
            <button className={styles['page-link']} onClick={onNext}>
              <Image src="/assets/content/chevron_right_FILL0_wght200_GRAD200_opsz48.svg" alt="Next" width="24" height="24" />
            </button>
          </li>
        ) : (
          ''
        )}
      </ul>
    </nav>
  )
}

export default Pagination
