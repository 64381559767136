import { useRouter } from 'next/router'
import { Kicker, LeftChevron } from '~elements'
import ResultModal, { ResultModalType } from '../../../elements/ResultModal/ResultModal'

import styles from './index.module.scss'
import { usePreviewStore } from '../../../../store'
import { PREVIEW_DISCONNECT_TYPE, PREVIEW_PATH } from '~constants'
import { handlePreviewDisconnect } from '~utils'

export const ExitPreviewButton = () => {
  const router = useRouter()
  const { displayExitModal, setDisplayExitModal } = usePreviewStore()

  const handleModalOpen = () => setDisplayExitModal(true)
  const handleModalClose = () => setDisplayExitModal(false)

  const { resetRenderingManager, renderingManager } = usePreviewStore()
  return (
    <>
      <button className={styles['exit-preview-button']} onClick={handleModalOpen}>
        <LeftChevron />
      </button>
      <ResultModal
        type={ResultModalType.WARNING}
        closeBtnText="Cancel"
        actionButtonText="Exit"
        isOpen={displayExitModal}
        handleModalClose={handleModalClose}
        actionButtonCallback={() => {
          router.push(PREVIEW_PATH).then(() => {
            handlePreviewDisconnect({
              renderingManager,
              resetRenderingManager,
              type: PREVIEW_DISCONNECT_TYPE.USER_PRESSED_EXIT_PREVIEW
            })
          })
        }}
      >
        <Kicker fontSize="16px" fontWeight="600" text="Are you sure you want to exit Preview?" letterSpacing="1px" textTransform="none" />
      </ResultModal>
    </>
  )
}
