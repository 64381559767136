import { Property } from 'csstype'
import TextTransform = Property.TextTransform

export * from './PreviewForm'

// Miscellaneous
export interface IKicker {
  fontWeight: string
  fontSize: string
  letterSpacing: string
  textTransform: TextTransform
  text: string
}
