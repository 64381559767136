export const ShareIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7423_12349" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="white" />
      </mask>
      <g mask="url(#mask0_7423_12349)">
        <path
          d="M6.6155 21.9992C6.15517 21.9992 5.77083 21.845 5.4625 21.5367C5.15417 21.2283 5 20.844 5 20.3837V10.6147C5 10.1543 5.15417 9.76999 5.4625 9.46166C5.77083 9.15332 6.15517 8.99916 6.6155 8.99916H8.73075V9.99916H6.6155C6.4615 9.99916 6.32042 10.0632 6.19225 10.1914C6.06408 10.3196 6 10.4607 6 10.6147V20.3837C6 20.5377 6.06408 20.6787 6.19225 20.8069C6.32042 20.9351 6.4615 20.9992 6.6155 20.9992H17.3845C17.5385 20.9992 17.6796 20.9351 17.8077 20.8069C17.9359 20.6787 18 20.5377 18 20.3837V10.6147C18 10.4607 17.9359 10.3196 17.8077 10.1914C17.6796 10.0632 17.5385 9.99916 17.3845 9.99916H15.2693V8.99916H17.3845C17.8448 8.99916 18.2292 9.15332 18.5375 9.46166C18.8458 9.76999 19 10.1543 19 10.6147V20.3837C19 20.844 18.8458 21.2283 18.5375 21.5367C18.2292 21.845 17.8448 21.9992 17.3845 21.9992H6.6155ZM11.5 15.4992V4.61266L9.4 6.71266L8.69225 5.99916L12 2.69141L15.3077 5.99916L14.6 6.71266L12.5 4.61266V15.4992H11.5Z"
          fill="currentFill"
          // To change the SVG color via CSS, use the `color` property in your SCSS.
          // For example, to set the color to red on hover:
          // .icon-container:hover svg { color: red; }
        />
      </g>
    </svg>
  )
}
