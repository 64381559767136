import React from 'react'
import { Modal } from '~elements'
import ModalHeader from './ModalRecipientsHeader'
import ModalBody from './ModalRecipientsBody'
import ModalFooter from './ModalRecipientsFooter'
import styles from './ManageRecipientsModal.module.scss'

interface RecipientsModalProps {
  fileName: string
  isOpen: boolean
  handleModalClose: () => void
  shareUrl?: string
}

const ManageRecipientsModal = ({ fileName, isOpen, handleModalClose, shareUrl }: RecipientsModalProps) => {
  return (
    <Modal isOpen={isOpen} isRecipientsModal={true} contentWidth={'narrow'} handleModalClose={handleModalClose}>
      <div className={styles['modal-content-container']}>
        <ModalHeader fileName={fileName} />
        <ModalBody />
        <ModalFooter fileName={fileName} shareUrl={shareUrl} />
      </div>
    </Modal>
  )
}

export default ManageRecipientsModal
