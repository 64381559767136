export const EllipsisIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9964 26.262C16.7527 26.262 16.5453 26.1752 16.3741 26.0017C16.2029 25.8281 16.1173 25.6195 16.1173 25.3757C16.1173 25.132 16.2041 24.92 16.3777 24.7398C16.5512 24.5595 16.7598 24.4694 17.0036 24.4694C17.2473 24.4694 17.4547 24.5589 17.6259 24.7381C17.7971 24.9173 17.8827 25.1327 17.8827 25.3843C17.8827 25.6284 17.7959 25.8357 17.6223 26.0063C17.4488 26.1768 17.2402 26.262 16.9964 26.262ZM16.9964 17.8819C16.7527 17.8819 16.5453 17.7951 16.3741 17.6215C16.2029 17.448 16.1173 17.2393 16.1173 16.9956C16.1173 16.7519 16.2041 16.5444 16.3777 16.3732C16.5512 16.2021 16.7598 16.1165 17.0036 16.1165C17.2473 16.1165 17.4547 16.2033 17.6259 16.3768C17.7971 16.5504 17.8827 16.759 17.8827 17.0027C17.8827 17.2465 17.7959 17.4539 17.6223 17.6251C17.4488 17.7963 17.2402 17.8819 16.9964 17.8819ZM16.9964 9.52898C16.7527 9.52898 16.5453 9.43938 16.3741 9.2602C16.2029 9.08102 16.1173 8.86562 16.1173 8.61402C16.1173 8.36991 16.2041 8.16259 16.3777 7.99207C16.5512 7.82158 16.7598 7.73633 17.0036 7.73633C17.2473 7.73633 17.4547 7.82311 17.6259 7.99668C17.7971 8.17024 17.8827 8.37888 17.8827 8.62259C17.8827 8.86633 17.7959 9.07833 17.6223 9.25857C17.4488 9.43884 17.2402 9.52898 16.9964 9.52898Z"
        fill="white"
      />
    </svg>
  )
}
