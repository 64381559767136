import { ForgotPasswordBtnColor, ILoginReducerAction, ILoginReducerState } from '~interfaces'
import { LOGIN_ACTIONS, VALIDATION_ACTIONS } from '~constants'

const loginReducer = (state: ILoginReducerState, action: ILoginReducerAction) => {
  switch (action.type) {
    case VALIDATION_ACTIONS.INVALID_INPUT: {
      return {
        ...state,
        ...action.payload
      }
    }
    case LOGIN_ACTIONS.SUBMIT: {
      return {
        ...state,
        ...action.payload
      }
    }

    case LOGIN_ACTIONS.SET_NEW: {
      return {
        ...state,
        fields: state.fields.map(field => ({ ...field, hidden: !field.hidden })),
        heading: {
          ...state.heading,
          title: 'Set new password'
        },
        paragraph: {
          ...state.paragraph,
          text: {
            html: `Please enter a new password below.`
          }
        },
        submitButton: {
          text: 'Continue',
          color: ForgotPasswordBtnColor.TURQUOISE
        },
        verificationCodeSent: true,
        backToSignIn: false,
        kicker: null,
        setNewPassword: true,
        setNewPasswordConfirm: false,

        ...action.payload,
        rememberMe: null
      }
    }

    case LOGIN_ACTIONS.SET_NEW_CONFIRM: {
      return {
        ...state,
        fields: [],
        heading: {
          ...state.heading,
          title: 'Password updated!'
        },
        paragraph: {
          ...state.paragraph,
          text: {
            html: `Password successfully set! Please click below to return to login.`
          }
        },
        submitButton: {
          text: 'Back to Login',
          color: ForgotPasswordBtnColor.WHITE
        },
        verificationCodeSent: false,
        backToSignIn: false,
        kicker: null,
        setNewPassword: false,
        setNewPasswordConfirm: true,

        ...action.payload,
        rememberMe: null
      }
    }

    default: {
      return state
    }
  }
}

export default loginReducer
