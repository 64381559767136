export const VideocamIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.64041 19.375C4.17736 19.375 3.77666 19.2058 3.43831 18.8675C3.09996 18.5291 2.93079 18.1284 2.93079 17.6654V6.3346C2.93079 5.87157 3.09996 5.47088 3.43831 5.13253C3.77666 4.79418 4.17736 4.625 4.64041 4.625H15.9712C16.4342 4.625 16.8349 4.79418 17.1733 5.13253C17.5116 5.47088 17.6808 5.87157 17.6808 6.3346V11.2077L21.0693 7.81922V16.1616L17.6808 12.7731V17.6654C17.6808 18.1284 17.5116 18.5291 17.1733 18.8675C16.8349 19.2058 16.4342 19.375 15.9712 19.375H4.64041ZM4.64041 18.2808H15.9712C16.1507 18.2808 16.2981 18.2231 16.4135 18.1077C16.5289 17.9923 16.5866 17.8449 16.5866 17.6654V6.3346C16.5866 6.15512 16.5289 6.00768 16.4135 5.8923C16.2981 5.77692 16.1507 5.71922 15.9712 5.71922H4.64041C4.46093 5.71922 4.31349 5.77692 4.19811 5.8923C4.08273 6.00768 4.02504 6.15512 4.02504 6.3346V17.6654C4.02504 17.8449 4.08273 17.9923 4.19811 18.1077C4.31349 18.2231 4.46093 18.2808 4.64041 18.2808Z"
        fill="white"
      />
    </svg>
  )
}
