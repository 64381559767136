import React, { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { Container } from 'react-grid-system'
import styles from './Header.module.scss'
import { getDynamicComponents } from 'utils'
import { HeaderProps } from '~types'
import { useOutsideClick, useWindowSize } from 'utils/hooks'
import { ExitPreviewButton } from './ExitPreviewButton'
import { VantagePoints } from '../RenderClient/VantagePoints'
import { TimeOfDay } from '../RenderClient/TimeOfDay'
import { FileName } from '../RenderClient/FileName'
import { LoggedInInitials } from './LoggedInInitials'
import BrandLogo from './BrandLogo'
import NavMenu from './NavMenu'
import NavShareVideo from '../RenderClient/NavShareVideo'

const Header = ({ ctaSection, isPreview = false, logo, navMenuPrimary, simple = false, videoPlayer = false }: HeaderProps) => {
  const { asPath } = useRouter()
  const [navDrawerIsOpen, handleNavDrawerToggle] = useState(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<', () => handleNavDrawerToggle(false))
  const isMobileBreakpoint = isBelowBreakpointMd
  const [openParentNavId, setOpenParentNavId] = useState('')
  const [subnavChildPage, setSubnavChildPage] = useState(true)
  const ref = useRef(null)

  const toggleNavDrawer = () => {
    handleNavDrawerToggle(navDrawerIsOpen ? false : true)
  }

  const closeParentNavClickHandler = () => {
    setOpenParentNavId('')
  }

  useOutsideClick(ref, () => {
    closeParentNavClickHandler()
    setSubnavChildPage(false)
  })

  const closeDrawerHandler = () => {
    if (navDrawerIsOpen) handleNavDrawerToggle(false)
  }

  const ctaButtons = () => {
    // @TODO: implement analytics
    // onClick={() => gaBtnClick(CTA_CLICK_EVENT, location, ctaTicketText)}
    // linkId={formatAnalyticsText(ctaFormText)}
    // linkLocation={ANALYTICS_LINK_LOCATION_HEADER}
    // linkModule={ANALYTICS_LINK_MODULE_LATEST_NEWS}

    return ctaSection.length ? <div className={styles['ctas']}>{getDynamicComponents(ctaSection)}</div> : null
  }

  const NavDesktop = () => {
    const colClassNames = [styles['header-global__column'], styles[videoPlayer ? 'header-global__video-player' : '']]

    return (
      <>
        <Container className={'container container--width-100'} style={{ padding: 0 }}>
          <div className={cx(colClassNames)}>
            {videoPlayer ? <ExitPreviewButton /> : null}
            <BrandLogo isPreview={isPreview} logo={logo} useAltLogo={true} />
            {videoPlayer ? (
              <>
                <FileName />
                <div className={styles['button-container']}>
                  <VantagePoints />
                  <TimeOfDay />
                  <NavShareVideo />
                </div>
              </>
            ) : null}
            <div className={styles['nav-container']}>
              {navMenuPrimary.length ? (
                <nav>
                  <NavMenu
                    navMenuPrimary={navMenuPrimary}
                    navDrawerIsOpen={navDrawerIsOpen}
                    subnavChildPage={subnavChildPage}
                    handleNavDrawerToggle={handleNavDrawerToggle}
                    setOpenParentNavId={setOpenParentNavId}
                    setSubnavChildPage={setSubnavChildPage}
                    openParentNavId={openParentNavId}
                  />
                </nav>
              ) : null}
              {isPreview ? <LoggedInInitials /> : ctaButtons()}
            </div>
          </div>
        </Container>
      </>
    )
  }

  const NavMobile = () => {
    const classNames = [styles['header-global__column'], styles['header-global__mobile'], styles[videoPlayer ? 'header-global__video-player' : '']]

    return (
      <>
        <div className={cx(classNames)}>
          {videoPlayer ? <ExitPreviewButton /> : null}
          <BrandLogo isPreview={isPreview} logo={logo} useAltLogo={true} />
          {videoPlayer ? (
            <>
              <FileName />
              <div className={styles['button-container']}>
                <VantagePoints />
                <TimeOfDay />
                <NavShareVideo />
              </div>
            </>
          ) : null}
          <div className={styles['nav-container']}>{isPreview ? <LoggedInInitials /> : ctaButtons()}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <header
        ref={ref}
        onClick={closeDrawerHandler}
        className={cx([
          styles['header-global'],
          styles[isMobileBreakpoint ? 'header-global--mobile' : 'header-global--desktop'],
          styles[navDrawerIsOpen && isMobileBreakpoint ? 'drawer-open' : ''],
          styles[ctaSection && ctaSection.length === 2 ? 'header-global--has-two-cta-btns' : ''],
          styles[simple ? 'header-global__simple' : ''],
          styles[asPath === '/preview' ? 'preview-page' : ''],
          styles[videoPlayer ? 'header-global__video-player' : '']
        ])}
      >
        {isMobileBreakpoint ? NavMobile() : NavDesktop()}
      </header>
    </>
  )
}

export default Header
