import Link from 'next/link'
import styles from './Breadcrumbs.module.scss'
import { BreadcrumbProps } from '~types'
import { ArrowBackIcon } from '../Icons'

const Breadcrumbs = ({ items = [] }: BreadcrumbProps) => {
  return (
    <div className={styles['breadcrumbs']}>
      <ol className={styles['breadcrumb-list']}>
        {items.map((item, index) => (
          <li key={index} className={styles['breadcrumb-item']}>
            {item.href ? (
              <Link href={item.href || '#'} className={styles['breadcrumb-link']}>
                {index === 0 && <ArrowBackIcon />}
                {item.label}
              </Link>
            ) : (
              <span>{item.label}</span> // Non-clickable breadcrumb if no href
            )}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default Breadcrumbs
