import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Logout } from '~lib/aws/cognito/services'
import { ResultModalType } from 'components/elements/ResultModal/ResultModal'
import { Kicker, LogOutIcon, Popover, ResultModal } from '~elements'
import { getCognitoUserInfo } from 'utils/user/getCognitoUserSession'
import styles from '../Header.module.scss'

export const LoggedInInitials = () => {
  const router = useRouter()
  const { asPath } = router
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<{ initials: string; name: string; email: string } | null>(null)

  const handleModalOpen = () => setIsOpen(true)
  const handleModalClose = () => setIsOpen(false)

  useEffect(() => {
    ;(async () => {
      const cognitoUserInfo = await getCognitoUserInfo()
      if (cognitoUserInfo) {
        setUserInfo(cognitoUserInfo)
      }
    })()
  }, [asPath])

  return userInfo ? (
    <>
      <Popover
        isConfirmationModalOpen={isOpen}
        popoverCTA={
          <button className={styles['logout-cta']} id="logout-cta">
            {userInfo.initials}
          </button>
        }
      >
        <div className={styles['user-info-container']}>
          <div className={styles['user-name-email-container']}>
            <Kicker fontSize="16px" fontWeight="600" text={userInfo.name} textTransform="none" letterSpacing="none" />
            <Kicker fontSize="14px" fontWeight="350" text={userInfo.email} textTransform="none" color="#C6C6C6" letterSpacing="none" />
          </div>
          <div className={styles['logout-container']}>
            <button onClick={() => handleModalOpen()} className={styles['logout']}>
              <LogOutIcon />
              log out
            </button>
          </div>
        </div>
      </Popover>
      <ResultModal
        type={ResultModalType.WARNING}
        closeBtnText="Cancel"
        actionButtonText="Logout"
        isOpen={isOpen}
        handleModalClose={handleModalClose}
        actionButtonCallback={() => {
          Logout()
          setUserInfo(null)
          router.replace('/preview/login')
        }}
      >
        <Kicker fontSize="16px" fontWeight="600" text="Are you sure you want to logout?" letterSpacing="1px" textTransform="none" />
      </ResultModal>
    </>
  ) : null
}
