import Image from 'next/image'
import { GalleryThumbnailProps } from '~types'
import styles from './GalleryThumbnail.module.scss'
import { Heading } from '~elements'

const GalleryThumbnail = ({ posterImageUrl, videoTitle, onClick }: GalleryThumbnailProps) => {
  const Thumbnail = () => {
    if (posterImageUrl) {
      return <Image src={posterImageUrl} alt="Video thumbnail" width="0" height="0" sizes="100vw" />
    }
  }

  return (
    <>
      {posterImageUrl && (
        <div className={`${styles['thumbnail-container']} image-container`} onClick={onClick}>
          {Thumbnail()}
          {videoTitle && (
            <div className={styles['title-container']}>
              <Heading level={3} levelDisplay={3}>
                {videoTitle}
              </Heading>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default GalleryThumbnail
