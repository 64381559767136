export const UploadFillIcon = ({ styles }: { styles: any }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className={styles['file-upload-icon']}>
      <path
        d="M19.3591 26.0926V10.8266L15.1604 14.9933L14.2341 14.0991L20.0002 8.33301L25.7662 14.0991L24.8399 14.9933L20.6412 10.8266V26.0926H19.3591ZM10.6412 31.6663C10.0007 31.6663 9.45595 31.4419 9.00695 30.9929C8.55798 30.5439 8.3335 29.9991 8.3335 29.3587V24.8747H9.61554V29.3587C9.61554 29.6151 9.72237 29.8501 9.93604 30.0638C10.1497 30.2775 10.3848 30.3843 10.6412 30.3843H29.3592C29.6156 30.3843 29.8506 30.2775 30.0643 30.0638C30.278 29.8501 30.3848 29.6151 30.3848 29.3587V24.8747H31.6668V29.3587C31.6668 29.9991 31.4423 30.5439 30.9934 30.9929C30.5444 31.4419 29.9996 31.6663 29.3592 31.6663H10.6412Z"
        fill="white"
      />
    </svg>
  )
}
