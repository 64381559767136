import React from 'react'
import styles from './ManageRecipientsModal.module.scss'
import { CopyAndShareButton, LinkIcon } from '~elements'

interface IModalRecipientsFooter {
  fileName: string
  shareUrl?: string
}

const ModalRecipientsFooter = ({ fileName, shareUrl }: IModalRecipientsFooter) => {
  return (
    <div className={styles['modal-footer']}>
      <div className={styles['access-section']}>
        <div className={styles['item']}>
          <span className={styles['name']}>Domain Access</span>
          <span className={styles['number']}>0/3</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Vantage Point</span>
          <span className={styles['number']}>32/32</span>
        </div>
        <div className={styles['item']}>
          <span className={styles['name']}>Times of Day</span>
          <span className={styles['number']}>3/3</span>
        </div>
      </div>
      <div className={styles['divider']}></div>
      <div className={styles['footer-buttons']}>
        <CopyAndShareButton
          displayText={'Copy link'}
          tooltipText={'Link Copied!'}
          styles={styles}
          shareTitle={`${fileName} | Exo Web Preview`}
          Icon={LinkIcon}
          url={shareUrl}
          useNavigatorShare={false}
        />
        <button className={styles['share-btn']}>Share</button>
      </div>
    </div>
  )
}

export default ModalRecipientsFooter
