import { Fragment, useEffect, useReducer, useState } from 'react'
import { useRouter } from 'next/router'

import { Heading, Kicker, Paragraph, RememberMe } from '~elements'

import styles from './index.module.scss'
import cx from 'classnames'
import Image from 'next/image'
import {
  ForgotFieldKeys,
  ForgotPasswordBtnColor,
  IField,
  IForgotPasswordReducerAction,
  IForgotPasswordReducerState,
  ILoginReducerAction,
  ILoginReducerState,
  IPreviewForm,
  PreviewForgotPasswordForm,
  PreviewLoginForm
} from '~interfaces'
import {
  getOrDeleteRememberMe,
  handleConfirmPassword,
  handleFailedLogin,
  handleInitPasswordVerification,
  handleInputValidation,
  handleLogin,
  handleResendVerificationCode,
  handleSetNewPassword
} from '~utils'
import { forgotPasswordReducer, loginReducer } from '~reducers'
import { VisibilityOnIcon, VisibilityOffIcon, PasswordRequirements } from '~elements'

const PreviewForm = ({ fields, formType, heading, kicker, paragraph, rememberMe, submitButton, backToSignIn }: IPreviewForm) => {
  const [newPassword, setNewPassword] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [newPasswordIsVisible, setNewPasswordIsVisible] = useState<boolean>(false)

  const router = useRouter()

  // These reducers control actions for this form. Please investigate them to find out more.
  const reducer = formType === 'login' ? loginReducer : forgotPasswordReducer
  const initialState: IPreviewForm = { fields, formType, heading, kicker, paragraph, rememberMe, submitButton, backToSignIn }
  const [state, dispatch] = useReducer<
    IForgotPasswordReducerState | ILoginReducerState | any,
    IForgotPasswordReducerAction | ILoginReducerAction | any
  >(reducer, initialState, () => {
    return initialState
  })

  /**
   * Validate a user's input onSubmit. Based on the reducer field `invalidFields`, the useEffect above will get invoked.
   * @param event
   */
  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    setIsDisabled(true)

    // Validate input and return if an error shows.
    const invalidFields = handleInputValidation(event as React.SyntheticEvent<PreviewForgotPasswordForm | PreviewLoginForm>, state.fields, dispatch)

    if (!invalidFields || Object.keys(invalidFields).length === 0) {
      try {
        if (formType === 'login') {
          if (state.setNewPasswordConfirm) {
            window.location.href = '/preview/login'
            return
          }

          if (state.setNewPassword) {
            await handleSetNewPassword(
              event as React.SyntheticEvent<PreviewLoginForm>,
              dispatch,
              state.session_code,
              state.email,
              state.userAttributes
            )
          } else {
            await handleLogin(event as React.SyntheticEvent<PreviewLoginForm>, dispatch, router)
          }
          // await router.replace('/preview')
        } else if (formType === 'forgot') {
          // User completed password reset and give them an option to go back to /preview/login.
          if (state.passwordConfirmed) {
            window.location.href = '/preview/login'
            return
          }

          // User initial request to get a verification code.
          if (!state.verificationCodeSent) {
            await handleInitPasswordVerification(event as React.SyntheticEvent<PreviewForgotPasswordForm>, dispatch)
          }

          // User sent verification code and new password.
          else {
            await handleConfirmPassword(event as React.SyntheticEvent<PreviewForgotPasswordForm>, dispatch, state.email)
          }
        }
      } catch (err) {
        console.error(err)
        const message = 'Something went wrong.'
        handleFailedLogin(state.fields, dispatch, message)
      } finally {
        setIsDisabled(false)
      }
    } else {
      setIsDisabled(false)
    }
  }

  const onResetClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    handleResendVerificationCode(state.email, dispatch)
  }

  const getError = (field: any) => {
    if (state.invalidFields && state.invalidFields[field.id]) {
      return (
        <div className={styles['error-message']}>
          <Image src={'/assets/icon-warning.svg'} alt="Warning" width="24" height="24" sizes="100vw" />
          {state.invalidFields[field.id].message}
        </div>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    const item = getOrDeleteRememberMe()
    if (item) {
      setEmail(item.email)
      setIsChecked(true)
    }
  }, [])

  const handleClick: React.MouseEventHandler<HTMLInputElement> = () => {
    setIsChecked(!isChecked)
  }

  const displayLabel = (id: string, label: string) => <label htmlFor={id}>{label}</label>

  const displayField = (field: IField) => {
    switch (field.id) {
      case ForgotFieldKeys.NEW_PASSWORD:
        return (
          <>
            <input
              type={newPasswordIsVisible ? 'text' : 'password'}
              placeholder={field.placeholder}
              id={field.id}
              data-testid="newpassword-forgot"
              value={newPassword}
              onChange={e => {
                setNewPassword(e.target.value)
              }}
              name={'new-password'}
              autoComplete="new-password" //for firefox
            />
            {displayLabel(field.id, field.label)}
            <span className={styles['toggle-password']} onClick={() => setNewPasswordIsVisible(!newPasswordIsVisible)}>
              {newPasswordIsVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
            </span>
          </>
        )
      case 'email':
        return (
          <>
            <input
              type={field.type}
              placeholder={field.placeholder}
              id={field.id}
              value={email}
              onChange={e => setEmail(e.target.value)}
              name={'email'}
              autoComplete={'email'}
            />
            {displayLabel(field.id, field.label)}
          </>
        )
      case 'password':
      case ForgotFieldKeys.CONFIRM_PASSWORD:
        return (
          <>
            <input
              type={isVisible ? 'text' : 'password'}
              placeholder={field.placeholder}
              id={field.id}
              name={field.id === 'password' ? 'current-password' : 'confirm-password'}
              autoComplete={field.id === 'password' ? 'current-password' : 'new-password'}
            />
            {displayLabel(field.id, field.label)}
            <span className={styles['toggle-password']} onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
            </span>
          </>
        )
      default:
        return (
          <>
            <input
              type={field.type}
              placeholder={field.placeholder}
              id={field.id}
              name={field.type}
              autoComplete={field.id === 'verificationCode' ? 'one-time-code' : field.type}
            />
            {displayLabel(field.id, field.label)}
          </>
        )
    }
  }

  return (
    <div className={styles['login-form-container']}>
      <div className={styles['login-form-top-content']}>
        {state.kicker ? <Kicker {...state.kicker} /> : null}
        {state.heading ? <Heading {...state.heading} /> : null}
        {state.resendCode && state.resendCodeKicker ? <Kicker {...state.resendCodeKicker} /> : null}
        {state.paragraph ? <Paragraph {...state.paragraph} /> : null}
        <form className={styles['login-form']} noValidate id="preview-form" onSubmit={event => onSubmit(event)}>
          {state.fields.map((field, index) => {
            if (!field.hidden) {
              return (
                <Fragment key={field.id}>
                  <div
                    className={cx([styles['form-group'], styles[state.invalidFields && state.invalidFields[field.id] ? 'form-group--error' : '']])}
                    key={index}
                  >
                    {displayField(field)}
                  </div>
                  {field.id !== ForgotFieldKeys.NEW_PASSWORD || !newPassword ? getError(field) : <PasswordRequirements text={newPassword} />}
                </Fragment>
              )
            }
          })}
          {state.rememberMe ? (
            <RememberMe
              forgotPassword={rememberMe.forgotPassword}
              disabled={email === '' && !isChecked}
              isChecked={isChecked}
              onClick={handleClick}
            />
          ) : null}
          <button
            type="submit"
            disabled={isDisabled}
            className={cx(styles[state.submitButton.color === ForgotPasswordBtnColor.WHITE ? 'btn-white' : ''])}
          >
            {state.submitButton.text}
          </button>
        </form>
        {state.backToSignIn ? (
          <>
            <a className={styles['back-to-signin']} href={state.backToSignIn.signIn.link}>
              {state.backToSignIn.signIn.text}
            </a>
          </>
        ) : null}
        {state.resendCode ? (
          <>
            <a className={styles['back-to-signin']} href="#" onClick={onResetClick}>
              Resend Code
            </a>
          </>
        ) : null}
      </div>
      <div className={styles['login-form-footer']}>
        <p>© {new Date().getFullYear()} Sphere Entertainment Group, LLC</p>
      </div>
      <div className={styles['login-full-screen__overlay']} />
      <video autoPlay={true} loop={true} muted playsInline={true}>
        <source src={'/assets/content/preview-login-refik-anadol.mp4'} type="video/mp4" />
      </video>
    </div>
  )
}

export default PreviewForm
