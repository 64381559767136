import { Kicker } from '~elements'
import styles from './index.module.scss'
import { usePreviewStore } from 'store'

export const FileName = () => {
  const { selectedVideo } = usePreviewStore()

  return selectedVideo.fileName ? (
    <div className={styles['filename-container']}>
      <Kicker width={'100%'} fontSize="16px" fontWeight="600" letterSpacing="none" textTransform="none" text={selectedVideo.fileName} />
    </div>
  ) : null
}
