export const PasswordHintErrorIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.27109 18.529L5.49609 17.729L11.2249 12.0001L5.49609 6.24629L6.27109 5.44629L12.0249 11.2001L17.7288 5.44629L18.5038 6.24629L12.7999 12.0001L18.5038 17.729L17.7288 18.529L12.0249 12.7751L6.27109 18.529Z"
        fill="#F52500"
      />
    </svg>
  )
}
