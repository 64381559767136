import axios from 'axios'

export const createPreviewUrl = (title: string) => {
  //filePath construction will change if/when we add folders
  const filePath = `upload/${title}`
  const type = 'inline'

  const presignedUrl = axios
    .get('/api/downloadfile', { params: { filePath, type } })
    .then(res => {
      return res.data.url
    })
    .catch(error => {
      throw error
    })

  return presignedUrl
}
