export const InfoIcon = () => {
  return (
    <svg data-icon="warning" width="43" height="40" viewBox="0 0 43 40" fill="none">
      <g clipPath="url(#clip0_6892_104560)">
        <path
          d="M27.2282 5.03747L39.8465 28.707C42.3322 33.3697 38.9533 39 33.6694 39H9.03377C3.81126 39 0.427882 33.4877 2.79216 28.831L14.8095 5.16152C17.374 0.110509 24.5634 0.0386944 27.2282 5.03747Z"
          fill="#FFD028"
          fillOpacity="0.2"
          stroke="#FFE27D"
          strokeWidth="2"
        />
        <path
          d="M20.2428 15.72H22.7628L22.4828 25.02H20.5228L20.2428 15.72ZM21.5028 26.44C22.3028 26.44 22.8828 27.04 22.8828 27.82C22.8828 28.6 22.3028 29.2 21.5028 29.2C20.7228 29.2 20.1228 28.6 20.1228 27.82C20.1228 27.04 20.7228 26.44 21.5028 26.44Z"
          fill="#FFE27D"
        />
      </g>
      <defs>
        <clipPath id="clip0_6892_104560">
          <rect width="43" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
