export const TheatersIcon = ({ styles }: { styles: any }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" className={styles['theater-fill-icon']}>
      <path
        d="M6 27.8327V5.16602H7.02563V7.52498H11.641V5.16602H20.359V7.52498H24.9744V5.16602H26V27.8327H24.9744V25.4737H20.359V27.8327H11.641V25.4737H7.02563V27.8327H6ZM7.02563 24.4481H11.641V19.8327H7.02563V24.4481ZM7.02563 18.807H11.641V14.1916H7.02563V18.807ZM7.02563 13.166H11.641V8.55062H7.02563V13.166ZM20.359 24.4481H24.9744V19.8327H20.359V24.4481ZM20.359 18.807H24.9744V14.1916H20.359V18.807ZM20.359 13.166H24.9744V8.55062H20.359V13.166ZM12.6667 26.807H19.3333V6.19165H12.6667V26.807Z"
        fill="white"
      />
    </svg>
  )
}
