//'use client'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { fireAnalytics, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_PAGE_LOAD } from '~analyticsConstants'
import { getDynamicComponents } from 'utils'

interface BlokInterface {
  body: Array<object>
}

interface PageProps {
  blok: BlokInterface
  components: any
  preview: boolean
  featureFlag?: string
}

const Page = ({ blok, components, featureFlag }: PageProps) => {
  const { body = {} } = blok
  const { blocks = [], slug = '' } = body
  const { asPath } = useRouter()
  const header = blocks.find(block => block.componentName === 'Header')
  const main = blocks.filter(block => block.componentName !== 'Header' && block.componentName !== 'Footer')
  const footer = blocks.find(block => block.componentName === 'Footer')

  useEffect(() => {
    fireAnalytics(ANALYTICS_EVENT_PAGE_LOAD, getPageType(asPath))
  }, [slug])

  return (
    <>
      {header ? getDynamicComponents([header], false) : null}
      <main>{getDynamicComponents(main, false)}</main>
      {footer ? getDynamicComponents([footer], false) : null}
    </>
  )
}
export default Page
