import React, { useState, useRef, useEffect } from 'react'
import { useOutsideClick } from 'utils/hooks'
import styles from './DropdownSelector.module.scss'
import { DownChevronIcon } from '~elements'

interface IOptions {
  scenarioId: string
  displayName: string
  doesExist?: boolean
}

interface DropdownSelectorProps {
  onChange: Function
  options: Array<IOptions>
  current: IOptions
  Icon: React.ComponentType
  showDivider?: boolean
}

const DropdownSelector = ({ onChange, options, current, Icon, showDivider = true }: DropdownSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(current?.displayName || options[0]?.displayName)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleItemClick = (item: IOptions) => {
    setSelected(item.displayName)
    setIsOpen(false)
    onChange(item.scenarioId)
  }

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false)
  })

  return (
    <div className={styles['dropdown-container']} ref={dropdownRef} data-testid="dropdown-selector">
      <button onClick={toggleDropdown}>
        <Icon />
        <div className={styles['item-display-name']}>{selected}</div>
        <DownChevronIcon />
      </button>
      <ul className={styles[isOpen ? 'visible' : 'hidden']} role="menu">
        {options.map((item, index) => (
          <React.Fragment key={item.scenarioId}>
            <li role="menuItem" className={selected === item.displayName ? styles['selected'] : ''} onClick={() => handleItemClick(item)}>
              <Icon />
              {item.displayName}
            </li>
            {index === 3 && showDivider ? <hr /> : null}
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
}

export default DropdownSelector
