import { LOCAL_STORAGE } from '~constants'

interface IRefresh {
  authorization: string
  token: string
  userId: string
}

export const refreshToken = async ({ authorization, token, userId }: IRefresh) => {
  try {
    // Create headers object to allow authorization bearer token to be sent.
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${authorization}`)

    // Send request
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/refresh`, {
      method: 'POST',
      body: JSON.stringify({
        token,
        userId
      }),
      headers
    })
    return await result.json()
  } catch (e) {
    // Fails for an unknown reason, remove local storage key.
    localStorage.removeItem(LOCAL_STORAGE.USER_KEY)

    console.error(e)
  }
}
