import { CognitoIdentityProviderClient, ForgotPasswordCommand, ConfirmForgotPasswordCommand } from '@aws-sdk/client-cognito-identity-provider'
import { CLIENT_ID } from '../../constants'
import { getSecretHash } from 'utils/user/getSecretHash'

interface IBasePassword {
  Username: string
}

interface IConfirmPassword extends IBasePassword {
  VerificationCode: string
  Password: string
}

interface IInitPasswordOutput {
  message: 'CodeSent' | ''
  statusCode: 200 | 400
  status: 'Success' | 'Error'
  name: string | null
}

const client = new CognitoIdentityProviderClient({ region: 'us-east-1' })

const InitPasswordRequest = ({ Username }: IBasePassword): Promise<IInitPasswordOutput> => {
  return new Promise(resolve => {
    const secretHash = getSecretHash(Username)

    const input = {
      ClientId: CLIENT_ID,
      SecretHash: secretHash,
      Username
    }

    const command = new ForgotPasswordCommand(input)

    client.send(command, (err, data) => {
      if (data) {
        resolve({ message: 'CodeSent', statusCode: 200, status: 'Success', name: null })
      }

      if (err) {
        const { statusCode } = err.$metadata
        const name = err.name

        resolve({ message: '', statusCode: 400, status: 'Error', name })
      }
    })
  })
}

const ConfirmPassword = async ({ Username, VerificationCode, Password }: IConfirmPassword) => {
  try {
    const secretHash = getSecretHash(Username)

    const input = {
      ClientId: CLIENT_ID,
      SecretHash: secretHash,
      Username,
      ConfirmationCode: VerificationCode,
      Password
    }

    const command = new ConfirmForgotPasswordCommand(input)

    return await client.send(command)
  } catch (error) {
    return error
  }
}

export default {
  init: InitPasswordRequest,
  confirm: ConfirmPassword
}
