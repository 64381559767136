import React from 'react'
import Image from 'next/image'
import { CarouselSlideProps } from '~types'

const CarouselSlide = ({ image, handleThumbNavClick }: CarouselSlideProps) => {
  const ImageComponent = () => {
    return <Image src={image.url} alt="Video thumbnail" width="0" height="0" sizes="100vw" />
  }

  return (
    <>
      {image ? (
        <div className={'image-container'} onClick={handleThumbNavClick}>
          {ImageComponent()}
        </div>
      ) : null}
    </>
  )
}

export default CarouselSlide
