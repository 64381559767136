import RenderStreamingManager from 'render-streaming-client/build/RenderStreamingManager'
import { PREVIEW_DISCONNECT_TYPE } from '~constants'

interface IHandlePreviewDisconnect {
  renderingManager: RenderStreamingManager | null
  resetRenderingManager: () => void
  type: PREVIEW_DISCONNECT_TYPE
}

const PREVIEW_MESSAGES = {
  [PREVIEW_DISCONNECT_TYPE.BEFORE_UNLOAD_EVENT]: 'A user has reloaded or attempted a loading of another page.',
  [PREVIEW_DISCONNECT_TYPE.IDLE_TIMEOUT]: 'The user has timed out (60m) and sent back to /preview/login.',
  [PREVIEW_DISCONNECT_TYPE.USER_PRESSED_EXIT_PREVIEW]: 'A user explicitly left preview and their WS has been disconnected.'
}

/**
 * Disconnect a user from matchmaker, signaling and unity.
 * @param renderingManager
 * @param resetRenderingManager
 * @param type
 */
export const handlePreviewDisconnect = ({ renderingManager, resetRenderingManager, type }: IHandlePreviewDisconnect) => {
  renderingManager
    ?.disconnect()
    .then(() => {
      console.log(PREVIEW_MESSAGES[type])
    })
    .catch(err => {
      console.error(`${err} happened when trying to disconnect!`)
    })
    .finally(() => {
      resetRenderingManager()
    })
}
