export const DeleteFileIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.39037 20.0002C7.01634 20.0002 6.69207 19.8629 6.41755 19.5884C6.14303 19.3139 6.00578 18.9896 6.00578 18.6156V5.65406H5V4.88481H8.85385V4.23096H15.1462V4.88481H19V5.65406H17.9942V18.6156C17.9942 18.9999 17.8595 19.3267 17.5902 19.5961C17.3208 19.8655 16.9939 20.0002 16.6096 20.0002H7.39037ZM17.225 5.65406H6.775V18.6156C6.775 18.7951 6.8359 18.9425 6.9577 19.0579C7.07948 19.1733 7.22371 19.231 7.39037 19.231H16.6096C16.7635 19.231 16.9045 19.1669 17.0327 19.0387C17.1609 18.9105 17.225 18.7694 17.225 18.6156V5.65406ZM9.88653 17.1579H10.6558V7.70213H9.88653V17.1579ZM13.3442 17.1579H14.1135V7.70213H13.3442V17.1579Z"
        fill="white"
      />
    </svg>
  )
}
