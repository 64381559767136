export const isValidFileVersion = (version: string) => {
  if (!version) {
    return false
  }

  if (!version.startsWith('v')) {
    return false
  }

  const versionNumber = version.substring(1, version.length)

  if (versionNumber.length !== 3) {
    return false
  }

  return !Number.isNaN(versionNumber)
}

export const isValidFileExtension = (filePath: string) => {
  // allowed file type
  var allowedExtensions = /(\.webm|\.mov|\.mp4)$/i
  return !!allowedExtensions.exec(filePath)
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}
