import { useEffect, useState, useRef, RefObject } from 'react'
import breakpoints from '../../styles/variables.breakpoints.module.scss'

/**
 * Check's to see if dom element (ref) is viewable within viewport.
 *
 * @param {RefObject<HTMLElement>} ref
 * @param {boolean} triggerOnce - optional parameter to trigger css only once
 * @returns {boolean} true means the dom element is within viewport, false means it is not
 */

function useOnScreen(ref: RefObject<HTMLElement>, triggerOnce?: boolean, rootMargin?: string) {
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [isOnScreen, setIsOnScreen] = useState(false)

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio > 0 && triggerOnce) {
          setIsOnScreen(entry.isIntersecting)
        } else if (entry.intersectionRatio > 0) {
          setIsOnScreen(entry.isIntersecting)
        } else if (entry.intersectionRatio === 0) {
          setIsOnScreen(false)
        }
      },
      { rootMargin: rootMargin || '-30px' }
    )
    ref.current && observerRef.current?.observe(ref.current)
    return () => {
      observerRef.current?.disconnect()
    }
  }, [])
  return isOnScreen
}

/**
 * Executes callback if clicked outside referenced dom element
 * @param {RefObject<HTMLElement>} ref
 * @param {*} callback - function used to change state
 */

function useOutsideClick(ref: RefObject<HTMLElement>, callback: () => void): void {
  const handleClick = (e: { target: EventTarget | null }) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  })
}

/**
 * Returns window size and whether size is above or below a specified breakpoint
 * if function is done calculating user's screen size on every window resize event
 * @param {string} breakpoint
 * @param {string} operator
 * @param {callback} callback - optional parameter that runs a function if needed
 * @returns {{number, boolean, boolean}} - object that returns window size, whether or not it's on or below breakpoint,
 * and if function is done calculating user's screen size
 */

function useWindowSize(
  breakpoint: string | 'xs' | 'sm' | 'md' | 'lg',
  operator: string | '>' | '>=' | '<' | '<=',
  callback?: () => void
): { windowSize: number; isBreakpoint: boolean; windowSizeCalculation: boolean } {
  const [windowSize, setWindowSize] = useState<number>(typeof window !== 'undefined' ? window.innerWidth : 0)
  const [isBreakpoint, setIsLowerThanSpecifiedBreakpoint] = useState<boolean>(false)
  // prevents flicking during calculating user's window size
  const [windowSizeCalculation, setWindowSizeCalculation] = useState<boolean>(false)
  const breakpointMapped: number = Number(breakpoints[breakpoint])

  const operators = {
    '<': (a: number, b: number) => a < b,
    '<=': (a: number, b: number) => a <= b,
    '>': (a: number, b: number) => a > b,
    '>=': (a: number, b: number) => a >= b
  }

  useEffect(() => {
    const breakpointBoolean = operators[operator](windowSize, breakpointMapped)
    if (!breakpointBoolean && callback) {
      // optional callback used in Header component
      callback()
    }
    setIsLowerThanSpecifiedBreakpoint(breakpointBoolean)
  }, [breakpointMapped, windowSize])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowSize(window.innerWidth)
      }
      handleResize()
      setWindowSizeCalculation(true)
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [windowSize])

  return { windowSize, isBreakpoint, windowSizeCalculation }
}

export { useOnScreen, useOutsideClick, useWindowSize }
