import { FieldType, FieldValidationType, FormType, HeadingProps, ParagraphProps } from '~types'
import { IKicker } from '~interfaces'
import { FORGOT_PASSWORD_ACTIONS, LOGIN_ACTIONS, VALIDATION_ACTIONS } from '~constants'
export const literal = <V extends keyof any>(v: V) => v
export interface IRememberMe {
  forgotPassword: {
    link: string
    text: string
  }
  disabled: boolean
  isChecked: boolean
  onClick: React.EventHandler<any>
}
export interface IBackToSignIn {
  signIn: {
    link: string
    text: string
  }
}

export interface LoginFormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement
  password: HTMLInputElement
  rememberMe: HTMLInputElement
  newPassword: HTMLInputElement
  confirmPassword: HTMLInputElement
}

export interface ForgotPasswordFormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement
  newPassword: HTMLInputElement
  confirmPassword: HTMLInputElement
  verificationCode: HTMLInputElement
}

export interface PreviewLoginForm extends HTMLFormElement {
  readonly elements: LoginFormElements
}

export interface PreviewForgotPasswordForm extends HTMLFormElement {
  readonly elements: ForgotPasswordFormElements
}

export interface IField {
  hidden: boolean
  label: string
  id: string
  placeholder: string
  type: FieldType
  validation: FieldValidationType
  required: boolean
}

interface ISubmitButton {
  text: string
  color: ForgotPasswordBtnColorType
}

export interface IPreviewForm {
  fields: Array<IField>
  formType: FormType
  heading: HeadingProps
  kicker: IKicker
  paragraph?: ParagraphProps
  rememberMe: IRememberMe
  submitButton: ISubmitButton
  backToSignIn: IBackToSignIn
}

export interface IForgotPasswordReducerAction {
  type: FORGOT_PASSWORD_ACTIONS | VALIDATION_ACTIONS
  payload: any
}

export interface IForgotPasswordReducerState {
  fields: Array<IField>
  heading: HeadingProps
  verificationCodeSent: boolean
  paragraph?: ParagraphProps
}

export interface ILoginReducerAction {
  type: LOGIN_ACTIONS | VALIDATION_ACTIONS
  payload: any
}

export interface ILoginReducerState {
  email: string
  fields: Array<IField>
  heading: HeadingProps
  verificationCodeSent: boolean
  paragraph?: ParagraphProps
}

export const ForgotFieldKeys = {
  NEW_PASSWORD: 'newPassword',
  CONFIRM_PASSWORD: 'confirmPassword'
} as const

export const ResetFieldKeys = {
  NEW_PASSWORD: 'newPassword',
  CONFIRM_PASSWORD: 'confirmPassword'
} as const

export const ForgotPasswordBtnColor = {
  WHITE: literal('white'),
  TURQUOISE: literal('turquoise')
} as const

type ForgotPasswordBtnColorType = (typeof ForgotPasswordBtnColor)[keyof typeof ForgotPasswordBtnColor]
