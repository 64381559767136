import React, { useEffect, useRef } from 'react'

interface IFileDragDrop {
  container: React.RefObject<HTMLDivElement>
  styles: { readonly [key: string]: string }
  fileValidation: Function
  uploadFile: Function
  isUploading: boolean
  setFileInfo: React.Dispatch<
    React.SetStateAction<{
      name: string
      size: number
    }>
  >
  fileInfo: {
    name: string
    size: number
  }
}

export const useFileDragDrop = ({ container, styles, fileValidation, uploadFile, isUploading, setFileInfo, fileInfo }: IFileDragDrop) => {
  const draggedFile = useRef<any>(null)

  function preventDefaults(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  function highlight(e: DragEvent) {
    if (container.current) {
      preventDefaults(e)
      container.current.classList.add(styles['highlight'])
    }
  }

  function unHighlight(e: DragEvent) {
    preventDefaults(e)

    if (container.current) {
      preventDefaults(e)
      container.current.classList.remove(styles['highlight'])
    }
  }

  function handleDrop(e: DragEvent) {
    if (fileInfo.name) {
      return
    }

    unHighlight(e)

    let dt = e.dataTransfer

    if (dt) {
      let files = dt.files

      const fileName = Array.from(files)[0].name
      const fileSize = Array.from(files)[0].size

      if (fileName && fileSize) {
        const fileSizeInKB = Math.round(fileSize / 1024)
        setFileInfo({ name: fileName, size: fileSizeInKB })

        fileValidation(fileName, fileSizeInKB)
        draggedFile.current = Array.from(files)[0]
      }
    }
  }

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('dragenter', highlight, false)
    }

    return () => {
      if (container.current) {
        container.current.removeEventListener('dragenter', highlight)
      }
    }
  }, [])

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('dragover', highlight, false)
    }

    return () => {
      if (container.current) {
        container.current.removeEventListener('dragover', highlight)
      }
    }
  }, [])

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('dragleave', unHighlight, false)
    }

    return () => {
      if (container.current) {
        container.current.removeEventListener('dragleave', unHighlight)
      }
    }
  }, [])

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('drop', handleDrop, false)
    }

    return () => {
      if (container.current) {
        container.current.removeEventListener('drop', handleDrop)
      }
    }
  }, [])

  useEffect(() => {
    if (isUploading) {
      uploadFile(draggedFile.current)
    }
  }, [isUploading])
}
