import { NavigationMenuItemProps, NavigationMenuItemsProps, SubNavLinksType } from '~types'
import { useRouter } from 'next/router'
import styles from '../Header.module.scss'
import { ConditionalWrapper } from '~elements'
import cx from 'classnames'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { disableBodyScroll } from '~utils'
import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_LOCATION_HEADER, ANALYTICS_LINK_MODULE_GLOBAL_NAV } from '~analyticsConstants'
import { useWindowSize } from 'utils/hooks'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface INavMenu {
  navMenuPrimary: NavigationMenuItemsProps
  navDrawerIsOpen: boolean
  subnavChildPage: boolean
  handleNavDrawerToggle: React.Dispatch<React.SetStateAction<boolean>>
  setOpenParentNavId: React.Dispatch<React.SetStateAction<string>>
  setSubnavChildPage: React.Dispatch<React.SetStateAction<boolean>>
  openParentNavId: string
}

const NavMenu = ({
  navMenuPrimary,
  navDrawerIsOpen,
  subnavChildPage,
  handleNavDrawerToggle,
  setOpenParentNavId,
  setSubnavChildPage,
  openParentNavId
}: INavMenu) => {
  const { asPath } = useRouter()
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<', () => handleNavDrawerToggle(false))

  const isMobileBreakpoint = isBelowBreakpointMd
  const [pathName, setPathName] = useState(asPath)
  const [onChildPage, setOnChildPage] = useState(false)

  useEffect(() => {
    disableBodyScroll(navDrawerIsOpen)
  }, [navDrawerIsOpen])

  useEffect(() => {
    setPathName(pathName)
    const childPageArr = navMenuPrimary.map(menuItems => menuItems.subnavMenu?.findIndex(subnavMenu => subnavMenu.url === asPath))
    const filterIndex = childPageArr.filter(index => (index as number) > -1)
    if ((filterIndex[0] as number) > -1) {
      setOnChildPage(true)
      setSubnavChildPage(true)
    } else {
      setOnChildPage(false)
      setOpenParentNavId('')
    }
  }, [asPath])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && !onChildPage) {
        setOpenParentNavId('')
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [onChildPage])

  const analyticsLinkData = (title: string) => ({
    linkId: formatAnalyticsText(title),
    linkModule: ANALYTICS_LINK_MODULE_GLOBAL_NAV,
    linkLocation: ANALYTICS_LINK_LOCATION_HEADER
  })

  const handleClick = (title: string) => {
    fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(title))
    handleNavDrawerToggle(false)
  }

  const handleParentNavItemClick = (id: string) => {
    setOpenParentNavId(openParentNavId === id ? '' : id)
  }

  const childPageSubnavClick = () => {
    setSubnavChildPage(subnavChildPage ? false : true)
  }

  const activeLink = (item: NavigationMenuItemProps) => {
    if (asPath === item.url) {
      return <span className={asPath === item.url ? styles['is-active-link'] : ''}>{item.title}</span>
    } else if (item.subNavLinks?.find(subNavLink => subNavLink.url === asPath)) {
      const currentChildPath = asPath.split('/')[1].toLowerCase()
      const parentSlug = item.url.slice(1).toLowerCase()
      return currentChildPath === parentSlug ? <span className={styles['is-active-link']}>{item.title}</span> : <span>{item.title}</span>
    }
    return <span>{item.title}</span>
  }

  const SubMenu = (item: NavigationMenuItemProps) => {
    return (
      <ul className={styles['submenu']}>
        {item.subNavLinks?.map((subnavItem: SubNavLinksType) => {
          return (
            <li className={asPath === subnavItem.url ? styles['is-current-page'] : ''} key={subnavItem.id}>
              <Link href={subnavItem.url} target={subnavItem.isTargetBlank ? '_blank' : '_self'} onClick={() => handleClick(subnavItem.title)}>
                {subnavItem.title}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <ul>
      {navMenuPrimary.map((item: NavigationMenuItemProps) => {
        if (item.title && item.url) {
          const hasSubmenu = item.subNavLinks && item.subNavLinks.length > 0

          return (
            <li
              key={item.id}
              onClick={() => (!onChildPage ? handleParentNavItemClick(item.id) : childPageSubnavClick())}
              className={cx([!onChildPage ? styles[openParentNavId === item.id ? 'is-open' : ''] : styles[subnavChildPage ? 'is-open' : '']])}
            >
              <div className={styles['menu-item-wrapper']}>
                <ConditionalWrapper
                  condition={!hasSubmenu}
                  wrapper={children => (
                    <Link href={item.url} target={item.isTargetBlank ? '_blank' : '_self'} onClick={handleClick}>
                      {children}
                    </Link>
                  )}
                >
                  {activeLink(item)}
                </ConditionalWrapper>

                {hasSubmenu && isMobileBreakpoint && (
                  <div className={styles['caret']}>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                )}
              </div>
              {hasSubmenu && !onChildPage && openParentNavId === item.id && <>{SubMenu(item)}</>}
              {hasSubmenu && onChildPage && subnavChildPage && <>{SubMenu(item)}</>}
            </li>
          )
        }
      })}
    </ul>
  )
}

export default NavMenu
