export const TheatersFillIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M6 27.3327V4.66602H7.02563V7.02498H11.641V4.66602H20.359V7.02498H24.9744V4.66602H26V27.3327H24.9744V24.9737H20.359V27.3327H11.641V24.9737H7.02563V27.3327H6ZM7.02563 23.9481H11.641V19.3327H7.02563V23.9481ZM7.02563 18.307H11.641V13.6916H7.02563V18.307ZM7.02563 12.666H11.641V8.05062H7.02563V12.666ZM20.359 23.9481H24.9744V19.3327H20.359V23.9481ZM20.359 18.307H24.9744V13.6916H20.359V18.307ZM20.359 12.666H24.9744V8.05062H20.359V12.666ZM12.6667 26.307H19.3333V5.69165H12.6667V26.307Z"
        fill="white"
      />
    </svg>
  )
}
