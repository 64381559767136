import { Property } from 'csstype'
import TextTransform = Property.TextTransform
import { ReactNode } from 'react'

export interface IKicker {
  fontWeight: string
  fontSize: string
  letterSpacing: string
  textTransform: TextTransform
  text: string | ReactNode
  opacity?: string
  [key: string]: any
}

const Kicker = ({ fontSize, fontWeight, letterSpacing, text, textTransform, opacity, ...rest }: IKicker) => {
  return <span style={{ fontSize, fontWeight, letterSpacing, textTransform, opacity, ...rest }}>{text}</span>
}

export default Kicker
