import React, { useState } from 'react'
import { CarouselSlide, Heading } from '~elements'
import { IVimeoGalleryVideo, Video } from '@msgtechnology/sphere'
import Slider, { Settings } from 'react-slick'
import { Container } from 'react-grid-system'
import styles from './Carousel.module.scss'
import { Parallax } from 'react-scroll-parallax'
import { useWindowSize } from 'utils/hooks'
import breakpoints from '../../../styles/variables.breakpoints.module.scss'

interface CarouselProps {
  autoPlay: boolean
  hasThumbnailNav?: boolean
  headline?: string
  subheadline?: string
  slides: Array<IVimeoGalleryVideo>
  playlist: Array<IVimeoGalleryVideo>
}

const Carousel = ({ autoPlay = false, hasThumbnailNav = false, headline, subheadline, slides, playlist }: CarouselProps) => {
  //passing playlist prop from Vimeo API for now. In the future, will fall back to slides in public/json/pages.js
  const [currentVideoUrl, setCurrentVideoUrl] = useState(playlist?.[0]?.video.url || '')
  const { isBreakpoint: isBreakpointMdOrBelow } = useWindowSize('md', '<=')
  const breakpointMd = Number(breakpoints['md'])

  const settings: Settings = {
    arrows: false,
    autoplay: autoPlay,
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 700,
    adaptiveHeight: false,
    centerMode: true,
    centerPadding: '0'
  }

  const responsiveSettings = [
    {
      breakpoint: breakpointMd,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]

  const [nav1, setNav1] = useState<Slider | null>(null)
  const [nav2, setNav2] = useState<Slider | null>(null)

  const handleThumbNavClick = (index: number) => {
    if (nav1) {
      nav1.slickGoTo(index)
    }
    setCurrentVideoUrl(playlist[index].video.url)
  }

  return (
    <div className={styles['carousel-container']} id="gallery">
      <Parallax opacity={[4, 0.2]} easing="easeInOut">
        <div className={styles['heading-container']}>
          {headline ? (
            <Heading level={2} levelDisplay="display" justify={'center'}>
              {headline}
            </Heading>
          ) : null}
          {subheadline ? (
            <Heading level={3} justify={'center'}>
              {subheadline}
            </Heading>
          ) : null}
        </div>
      </Parallax>
      <Parallax scale={[1, 0.8]} easing="easeInOutQuint">
        <Container className="container" style={{ maxWidth: '1600px', width: '100vw', paddingLeft: 0, paddingRight: 0 }}>
          <Slider
            {...settings}
            arrows={!hasThumbnailNav}
            asNavFor={nav2 ? nav2 : undefined}
            className="slide-overflow-is-visiblex"
            ref={slider1 => setNav1(slider1)}
          >
            {playlist?.map(slide => (
              <Video {...slide} id={slide.sId} key={slide.sId} displayThumbnail={false} />
            ))}
          </Slider>
        </Container>
      </Parallax>
      <Container className={`${styles['carousel-playlist']} container overflow-visible`} style={{ maxWidth: '1600px', width: '90%' }}>
        <Slider
          {...settings}
          className="is-thumbnail-nav slide-overflow-is-visible"
          arrows={!isBreakpointMdOrBelow}
          dots={isBreakpointMdOrBelow}
          focusOnSelect={true}
          centerMode={false}
          responsive={responsiveSettings}
          slidesToShow={3}
        >
          {playlist?.map((slide, index) => (
            <div className={`slide--thumbnail ${currentVideoUrl === slide.video.url ? 'is-active-thumb-nav' : ''}`} key={index}>
              {slide.video.posterImage && slide.video.title && (
                <CarouselSlide
                  image={{
                    fieldtype: 'asset',
                    fileName: slide.video.title,
                    image: { url: slide.video.posterImage },
                    url: slide.video.posterImage
                  }}
                  handleThumbNavClick={() => handleThumbNavClick(index)}
                />
              )}
              <p onClick={() => handleThumbNavClick(index)}>{slide.video.title}</p>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  )
}

export default Carousel
