import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'
import styles from './ResultModal.module.scss'
import { disableBodyScroll } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faExclamationTriangle, faClose } from '@fortawesome/free-solid-svg-icons'
import { InfoIcon } from '../Icons'

export enum ResultModalType {
  WARNING = 1,
  INFO = 2,
  ERROR = 3,
  SUCCESS = 4
}

export interface ConfirmationModalProps {
  children: any
  closeOnOutsideClick?: boolean
  handleModalClose: () => void
  isOpen: boolean
  type: ResultModalType | null
  panel?: boolean
  closeBtnText?: string
  actionButtonText?: string
  actionButtonCallback?: Function
}

const ResultModal = ({
  isOpen,
  handleModalClose,
  children,
  closeOnOutsideClick = true,
  type = ResultModalType.WARNING,
  closeBtnText = 'Close',
  actionButtonText = 'Action',
  actionButtonCallback = () => null
}: ConfirmationModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    const { key } = event
    if (key === 'Escape' && handleModalClose) {
      handleModalClose()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  if (!type) {
    return <></>
  }

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }

  const classNames = cx([styles['modal-content'], ...(type ? [styles[`modal-content--${type}`]] : [])])

  const handleCloseButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    handleModalClose()
  }

  const handleActionButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    actionButtonCallback()

    setTimeout(() => {
      handleModalClose()
    }, 100)
  }

  const getInfoIcon = () => {
    switch (type) {
      case ResultModalType.WARNING:
        return <InfoIcon />

      case ResultModalType.INFO:
        return <FontAwesomeIcon icon={faCircleInfo} color="#7f56d9" />

      case ResultModalType.ERROR:
        return <FontAwesomeIcon icon={faExclamationTriangle} color="#D92D20" />

      default:
        return <></>
    }
  }

  const modalContent = isOpen ? (
    <div className={styles.modal}>
      <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
        <div className={classNames}>
          <button className={styles['icon-close-container']} onClick={handleModalClose} data-testid="modal-close-icon-btn">
            <FontAwesomeIcon icon={faClose} color="#fff" size="sm" focusable />
          </button>
          {getInfoIcon()}
          {children}
          <div className={styles['cta-panel']}>
            <button data-type="secondary" onClick={handleCloseButtonClick}>
              {closeBtnText}
            </button>
            {type === ResultModalType.WARNING && (
              <button data-type="primary" onClick={handleActionButtonClick}>
                {actionButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    //@ts-ignore
    return ReactDOM.createPortal(modalContent, document.getElementById('root-modal'))
  } else {
    return null
  }
}

export default ResultModal
