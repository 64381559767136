import React, { Fragment, useEffect, useRef, useState } from 'react'
import styles from './ListView.module.scss'
import { useWindowSize } from 'utils/hooks'
import { useRouter } from 'next/router'
import { PREVIEW_PAGE_BREAKPOINT } from '~constants'
import { IVideos } from 'components/modules/Dashboard'
import { deleteFile } from 'services/deleteFile'
import Kicker from '../Kicker'
import ResultModal, { ResultModalType } from '../ResultModal/ResultModal'
import Image from 'next/image'
import cx from 'classnames'
import SingleRecord from './SingleRecord'

export interface FileRecord extends IVideos {}

interface ListViewProps {
  companyName: string
  records: IVideos[]
}

const ListView = ({ companyName, records }: ListViewProps) => {
  const router = useRouter()
  const { windowSize } = useWindowSize('lg', '<=')
  const isBelowBreakpointLg = windowSize <= PREVIEW_PAGE_BREAKPOINT
  const [genericError, setGenericError] = useState<string | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [dropdownOpen, _setDropdownOpen] = useState<boolean>(false)
  const dropdownOpenRef = React.useRef(dropdownOpen)
  const setDropdownOpen = (data: boolean) => {
    dropdownOpenRef.current = data
    _setDropdownOpen(data)
  }
  const [selectedRecord, _setSelectedRecord] = useState<IVideos | undefined>(undefined)
  const selectedRecordRef = React.useRef(selectedRecord)
  const setSelectedRecord = (record: IVideos) => {
    selectedRecordRef.current = record
    _setSelectedRecord(record)
  }
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const tableClassnames = cx([styles['box'], styles[dropdownOpen ? 'dropdown-open' : '']])

  const handleClick = (e: { target: EventTarget | null }) => {
    const { target } = e
    const fileName = (target as HTMLButtonElement)?.closest('button')?.value
    const dropdownButtonClicked = (target as HTMLButtonElement)?.closest('button')?.id === 'dropdown-button'
    //If a dropdown menu is open and a user clicks outside the dropdown, close it
    //If a dropdwn button is clicked and the filename matches the selected record, toggle dropdownOpen
    if (
      (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) ||
      (dropdownButtonClicked && fileName === selectedRecordRef?.current?.title)
    ) {
      setDropdownOpen(!dropdownOpenRef.current)
    }
    //If a user clicks a dropdown button, call handleDropDownButtonClick
    else if (dropdownButtonClicked) {
      handleDropdownButtonClick(e)
    }
  }

  const handleDropdownButtonClick = (e: { target: EventTarget | null }) => {
    const { target } = e
    const fileName = (target as HTMLButtonElement)?.closest('button')?.value
    const file = records.find(record => record.title === fileName)
    if (file) {
      setDropdownOpen(true)
      setSelectedRecord(file)
    }
  }

  const handleDelete = async (id: string, filename: string) => {
    try {
      await deleteFile({ videoId: id })
      //After deleting the file, reload the page so videos are refetched
      router.reload()
    } catch (error) {
      console.error(error)
      setDropdownOpen(false)
      setGenericError(`Failed to delete file ${filename}.`)
    }
  }

  const scrollToError = () => {
    const errorTop = document.getElementById('error-message')?.getBoundingClientRect().top
    const bodyTop = document.body.getBoundingClientRect().top
    const headerHeight = document.getElementsByTagName('header')[0]?.getBoundingClientRect().height
    if (errorTop) {
      const errorLocation = errorTop - bodyTop - headerHeight
      if (window.scrollY > errorLocation) {
        window.scrollTo({ behavior: 'smooth', top: errorLocation })
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (genericError !== null) {
      scrollToError()
      timeoutId = setTimeout(() => {
        setGenericError(null)
      }, 3000)
    }

    return () => clearTimeout(timeoutId)
  }, [genericError])

  return (
    <>
      {genericError ? (
        <div className={styles['error-message']} id="error-message">
          <Image src={'/assets/icon-warning.svg'} alt="Warning" width="24" height="24" sizes="100vw" />
          {genericError}
        </div>
      ) : null}
      <table className={tableClassnames}>
        <thead>
          <tr>
            <th>{companyName}</th>
            {!isBelowBreakpointLg && (
              <>
                <th>file size</th>
                <th>date uploaded</th>
                <th>uploaded by</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody id="preview">
          {records.map((record, idx) => (
            <SingleRecord
              key={idx}
              record={record}
              selectedRecord={selectedRecord}
              setGenericError={setGenericError}
              setDropdownOpen={setDropdownOpen}
              dropdownOpen={dropdownOpen}
              ref={dropdownRef}
              setDeleteModalOpen={setDeleteModalOpen}
            />
          ))}
        </tbody>
      </table>
      <ResultModal
        closeBtnText={'Cancel'}
        type={ResultModalType.WARNING}
        actionButtonText={'Delete'}
        isOpen={deleteModalOpen}
        handleModalClose={() => {
          setDeleteModalOpen(false)
        }}
        actionButtonCallback={() => {
          if (selectedRecord) {
            console.log('Deleting record: ', selectedRecord?.title)
            handleDelete(selectedRecord?.id, selectedRecord?.title)
          }
        }}
      >
        {
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Kicker fontSize="18px" fontWeight="500" letterSpacing="none" textTransform="none" text={`Permanently delete this content file?`} />
            <Kicker
              fontSize="18px"
              fontWeight="500"
              letterSpacing="none"
              textTransform="none"
              text={`This cannot be undone.`}
              padding={'0px 0px 12px'}
            />
            <Kicker
              wordBreak="break-all"
              fontSize="14px"
              fontWeight="400"
              letterSpacing="none"
              textTransform="none"
              text={selectedRecord?.title}
              opacity={'0.6'}
            />
          </div>
        }
      </ResultModal>
    </>
  )
}

export default ListView
