import { LOCAL_STORAGE } from '~constants'

function getOrDeleteRememberMe() {
  const rememberMeItem = localStorage.getItem(LOCAL_STORAGE.EXO_REMEMBER_ME)

  //If it exists, check if the expiry has passed
  if (rememberMeItem) {
    const item = JSON.parse(rememberMeItem)
    //If the expiry has passed, delete the item from local storage
    if (Date.now() > item.expiry) {
      localStorage.removeItem(LOCAL_STORAGE.EXO_REMEMBER_ME)
    } else {
      return item
    }
  }
}

export { getOrDeleteRememberMe }
export * from './handlers'
