import { useEffect, useState } from 'react'
import { Vimeo, CopyAndShareButton, Heading, ShareIcon } from '~elements'
import styles from './VideoDetails.module.scss'
import { VideoDetailsProps, VimeoProps } from '~types'
import { DisplayTags } from '@msgtechnology/sphere'
import { isMobile } from 'react-device-detect'

const VideoDetails = ({ galleryPlaylist }: VideoDetailsProps) => {
  const [videoDetails, setVideoDetails] = useState<VimeoProps | null>(null)
  const shareUrl = window.location.href

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('sId')
    const title = params.get('videoTitle')

    const videoDetail = galleryPlaylist.find(video => {
      if (id && video.sId === id) return true
      if (title && String(video.video.title).toLocaleLowerCase() === String(title).toLocaleLowerCase()) return true
      return false
    })
    setVideoDetails(videoDetail)
  }, [galleryPlaylist])

  if (!videoDetails) {
    return <div>Loading video details...</div>
  }

  return (
    <div className={styles['video-details-container']}>
      <div className={styles['video-details-header']}>
        <span>{videoDetails.video.title}</span>
        <div className={styles['share-button']}>
          <CopyAndShareButton
            displayText="Share"
            tooltipText="Link Copied!"
            styles={styles}
            shareTitle={`${videoDetails.video.title} - Watch this video`}
            Icon={ShareIcon}
            url={shareUrl}
            useNavigatorShare={isMobile ? true : false}
          />
        </div>
      </div>
      <Vimeo
        id={videoDetails.id}
        url={videoDetails.video.url}
        posterImage={videoDetails.video.posterImage}
        videoTitle={videoDetails.video.title}
        uploadDate={videoDetails.video.uploadDate}
        thumbnailUrl={videoDetails.thumbnail}
        externalUrl={videoDetails.externalUrl}
        videoDescription={videoDetails.videoDescription}
        autoplay={true}
        displayThumbnail={videoDetails.displayThumbnail}
      />
      <div className={styles['tags-container']}>
        <DisplayTags tags={videoDetails.video.tags} />
      </div>
    </div>
  )
}

export default VideoDetails
