import React, { useState, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import styles from './Vimeo.module.scss'
import { GalleryThumbnail, Heading, JsonLD, Modal } from '~elements'
import { VimeoProps } from '~types'
// import { fireAnalytics, formatAnalyticsText, getPageType } from '~analyticsUtils'
// import { ANALYTICS_EVENT_BUTTON_CLICK, ANALYTICS_LINK_LOCATION_BODY, ANALYTICS_LINK_MODULE_VIDEO } from '~analyticsConstants'
import { useIsIntersecting } from 'utils/hooks/useIsIntersecting'

const Vimeo = ({
  id,
  url,
  posterImage,
  externalUrl,
  videoTitle,
  videoDescription,
  uploadDate,
  thumbnailUrl,
  autoplay = false,
  displayThumbnail = false
}: VimeoProps) => {
  const { asPath } = useRouter()
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const intersectEntry = useIsIntersecting({ ref: containerRef })

  // const analyticsLinkData = (title: string) => ({
  //   linkId: formatAnalyticsText(title),
  //   linkModule: ANALYTICS_LINK_MODULE_VIDEO,
  //   linkLocation: ANALYTICS_LINK_LOCATION_BODY
  // })

  // const handlePosterClick = (alt: string) => {
  //   fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), analyticsLinkData(alt))
  //   if (externalUrl) {
  //     window.open(externalUrl, '_blank')
  //   } else {
  //     setModalIsOpen(true)
  //   }
  // }

  const handlePauseVideo = () => {
    videoRef.current?.pause()
  }

  useEffect(() => {
    if (url && intersectEntry) {
      const { isIntersecting } = intersectEntry
      const isLoaded = videoRef.current?.readyState === 4

      if (isIntersecting && !isLoaded) {
        videoRef.current?.load()
      }
    }
  }, [intersectEntry?.isIntersecting, url])

  useEffect(() => {
    //If the current video leaves the viewport while it is playing, it will pause
    //It will pause if it is scrolled out of view and/or if another video is selected
    if (intersectEntry) {
      const { isIntersecting } = intersectEntry
      const isPaused = videoRef.current?.paused
      if (!isIntersecting && !isPaused) {
        handlePauseVideo()
      }
    }
  }, [intersectEntry?.isIntersecting])

  const handleModalClose = () => setModalIsOpen(false)
  const handleModalOpen: React.MouseEventHandler<HTMLDivElement> = () => {
    setModalIsOpen(true)
  }
  const [videoHeight, setVideoHeight] = useState<number>()
  const ratio = 0.5625
  const modalPaddingOffset = 60

  // Dynamically determine iframe height. Without this modal will not close on overlay click.
  const handleChangeVideoWidth = useCallback(() => {
    const height = (window.innerWidth - modalPaddingOffset) * ratio
    return setVideoHeight(height)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleChangeVideoWidth)
    const height = (window.innerWidth - modalPaddingOffset) * ratio
    setVideoHeight(height)

    return () => {
      window.removeEventListener('resize', handleChangeVideoWidth)
    }
  }, [videoHeight, handleChangeVideoWidth])

  const vimeoVideoHTML = (url: string, autoPlay: boolean) => {
    return (
      <div className={styles['vimeo-container']} ref={containerRef}>
        <div className={styles['video-container']}>
          <video
            id={id}
            autoPlay={autoPlay}
            loop={false}
            muted
            playsInline={true}
            ref={videoRef}
            poster={posterImage?.url}
            width={'100%'}
            height={thumbnailUrl && videoHeight}
            controls
            preload={'none'}
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    )
  }

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: videoTitle,
    description: videoDescription ? videoDescription : videoTitle,
    thumbnailUrl: thumbnailUrl,
    uploadDate: uploadDate,
    contentUrl: url
  }

  return (
    <>
      <JsonLD data={structuredData} />
      {displayThumbnail && videoTitle && posterImage?.url ? (
        <>
          <GalleryThumbnail videoTitle={videoTitle} posterImageUrl={posterImage?.url} onClick={handleModalOpen} />
          {url ? (
            <Modal handleModalClose={handleModalClose} isOpen={modalIsOpen} contentWidth={'wide'}>
              {videoTitle && (
                <div className={styles['modal-container']}>
                  <Heading level={3} levelDisplay={1}>
                    {videoTitle}
                  </Heading>
                  {vimeoVideoHTML(url, autoplay)}
                </div>
              )}
            </Modal>
          ) : null}
        </>
      ) : url ? (
        vimeoVideoHTML(url, autoplay)
      ) : (
        ''
      )}
    </>
  )
}
export default Vimeo
