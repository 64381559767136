import React from 'react'
import styles from './ManageRecipientsModal.module.scss'
import { ExoVideoTitle } from '~elements'
import { Heading } from '@msgtechnology/sphere'

interface ModalHeaderProps {
  fileName: string
}

const ModalHeader = ({ fileName }: ModalHeaderProps) => {
  return (
    <div className={styles['modal-header']}>
      <Heading level={4}>
        {`Share "`}
        <ExoVideoTitle title={fileName} />
        {`"`}
      </Heading>
    </div>
  )
}

export default ModalHeader
