import { useState } from 'react'
import styles from './index.module.scss'
import Modal from 'components/elements/Modal/Modal'
import { EncryptedIcon } from 'components/elements/Icons'

const PreviewViewerForm = () => {
  const [email, setEmail] = useState('')

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  return (
    <Modal isOpen={true} handleModalClose={() => {}} showCloseButton={false}>
      <div className={styles['email-modal']}>
        <div className={styles['modal-header']}>
          <EncryptedIcon />
          <h4>Enter email address</h4>
          <p>Please enter your email address.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" className={styles['email-input']} />
          <div className={styles['cta-container']}>
            <button type="submit" className={styles['continue-button']}>
              Continue
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default PreviewViewerForm
