export const SunnyIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4654 5.45777V1.86353H12.5596V5.45777H11.4654ZM16.9961 7.77892L16.2404 7.02315L18.75 4.43085L19.5442 5.23085L16.9961 7.77892ZM18.5423 12.5347V11.4404H22.1365V12.5347H18.5423ZM11.4654 22.1116V18.5366H12.5596V22.1116H11.4654ZM7.0365 7.75392L4.4365 5.25007L5.27498 4.47507L7.79805 7.00392L7.0365 7.75392ZM18.8 19.5385L16.2404 16.977L16.9904 16.2462L19.5308 18.7443L18.8 19.5385ZM1.88843 12.5347V11.4404H5.48268V12.5347H1.88843ZM5.2365 19.5636L4.49998 18.7251L6.97883 16.2462L7.3846 16.6137L7.7846 17.0155L5.2365 19.5636ZM12.0045 17.0001C10.6169 17.0001 9.43587 16.5144 8.4615 15.5431C7.48715 14.5717 6.99998 13.3922 6.99998 12.0046C6.99998 10.617 7.48564 9.43597 8.45698 8.4616C9.42833 7.48725 10.6078 7.00007 11.9955 7.00007C13.3831 7.00007 14.5641 7.48574 15.5385 8.45708C16.5128 9.42843 17 10.6079 17 11.9956C17 13.3832 16.5143 14.5642 15.543 15.5386C14.5716 16.5129 13.3921 17.0001 12.0045 17.0001ZM11.9969 15.9059C13.072 15.9059 13.9923 15.5242 14.7577 14.7608C15.5231 13.9975 15.9058 13.0783 15.9058 12.0031C15.9058 10.928 15.5241 10.0078 14.7607 9.24238C13.9974 8.47699 13.0782 8.0943 12.0031 8.0943C10.9279 8.0943 10.0077 8.47598 9.24228 9.23933C8.47689 10.0027 8.0942 10.9219 8.0942 11.997C8.0942 13.0721 8.47588 13.9924 9.23923 14.7578C10.0026 15.5232 10.9218 15.9059 11.9969 15.9059Z"
        fill="white"
      />
    </svg>
  )
}
