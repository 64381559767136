import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Container } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faMap } from '@fortawesome/free-solid-svg-icons'
import { Heading, SocialMediaRow } from '~elements'
import { FooterProps, NavigationMenuItemProps, NavigationMenuItemsProps } from '~types'
import { ANALYTICS_LINK_LOCATION_FOOTER, CTA_CLICK_EVENT } from '~analyticsConstants'
import { analyticsClickHandler } from '~analyticsUtils'
import styles from './Footer.module.scss'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { useWindowSize } from 'utils/hooks'
import PrivacyIcon from 'public/assets/PrivacyIcon.png'
import { Fragment } from 'react'

const Footer = ({
  generalInformation,
  legalText,
  logoPrimary,
  logoSecondary,
  navMenu1,
  navMenu2,
  navMenu3,
  navMenuLegal,
  socialMediaRow,
  socialMediaRowHeadline
}: FooterProps) => {
  const { asPath, query } = useRouter()
  const requestCameFrom = query.camefrom
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')

  const onPhoneClickEvent = () => {
    gaBtnClick(CTA_CLICK_EVENT, ANALYTICS_LINK_LOCATION_FOOTER, generalInformation.phoneNumber)
  }

  const analyticsClick = (title: string) => {
    analyticsClickHandler(title, asPath)
    gaBtnClick(CTA_CLICK_EVENT, ANALYTICS_LINK_LOCATION_FOOTER, title)
  }

  const findReplaceDynamicYear = (text: string) => {
    return text.indexOf('${YEAR}') !== -1 ? text.replace('${YEAR}', new Date().getFullYear()) : text
  }

  const footerNavMenu = (menuItems: NavigationMenuItemsProps, headline: string) => {
    return (
      <>
        {menuItems.length ? (
          <>
            {headline && <p className={styles['column__header']}>{headline}</p>}
            <ul className={styles['column__content']}>
              {menuItems.map(
                ({ url, isTargetBlank, title }: NavigationMenuItemProps, navIndex: number) =>
                  url && (
                    <Fragment key={navIndex}>
                      {title === 'ONETRUST' ? (
                        <li key={navIndex} onClick={() => analyticsClick(title)} className={styles['ot-list-item']}>
                          <a className="ot-sdk-show-settings" onClick={() => window.OneTrust && window.OneTrust.ToggleInfoDisplay()}>
                            Your Privacy Choices
                          </a>
                          <Image src={PrivacyIcon} alt="privacy icon" width="30" height="12" />
                        </li>
                      ) : (
                        <li key={navIndex} onClick={() => analyticsClick(title)}>
                          <Link href={requestCameFrom ? `${url}?camefrom=${requestCameFrom}` : url} target={isTargetBlank ? '_blank' : '_self'}>
                            {findReplaceDynamicYear(title)}
                          </Link>
                        </li>
                      )}
                    </Fragment>
                  )
              )}
            </ul>
          </>
        ) : null}
      </>
    )
  }

  return (
    <>
      <footer className={styles['footer-global']}>
        <Container className={'container container--width-100'}>
          <div className={styles['row']}>
            <div className={styles['column-1']}>
              {socialMediaRowHeadline ? <p className={styles['column__header']}>{socialMediaRowHeadline}</p> : null}
              <SocialMediaRow align={isBelowBreakpointLg ? 'center' : 'left'} socialMediaRow={socialMediaRow} />
            </div>
            <div className={styles['column-2']}>
              <div className={styles['column-2__row']}>
                {generalInformation && (
                  <div className={styles['column-2__column']}>
                    <p className={styles['column__header']}>General Information</p>
                    <div className={styles['column__content']}>
                      <p className="align-contents-vertical">
                        <FontAwesomeIcon icon={faMap} />
                        {generalInformation.addressLine1}
                        {isBelowBreakpointMd ? <br /> : ', '}
                        {generalInformation.addressLine2}
                      </p>
                      <p>
                        <a className="align-contents-vertical" onClick={onPhoneClickEvent} href={`tel:+1${generalInformation.phoneNumber}`}>
                          <FontAwesomeIcon icon={faPhoneVolume} />
                          {generalInformation.phoneNumber}
                        </a>
                      </p>
                    </div>
                  </div>
                )}
                <div className={styles['column-2__column']}>{footerNavMenu(navMenu1, 'Partner with Us')}</div>
                <div className={styles['column-2__column']}>{footerNavMenu(navMenu2, 'Resources')}</div>
                <div className={styles['column-2__column']}>{footerNavMenu(navMenu3, 'About Us')}</div>
              </div>
            </div>
          </div>
        </Container>
        <Container className={'container container--width-100'}>
          <div className={styles['footer-global__bottom']}>
            <div className={styles['bottom__logos']}>
              <div className={styles['logos-row']}>
                {logoPrimary ? (
                  <div className={styles['logo']}>
                    <div className={'image-container'}>
                      <Image src={logoPrimary.url} alt="Sphere partner" width="0" height="0" sizes="100vw" priority />
                    </div>
                  </div>
                ) : null}
                {logoSecondary ? (
                  <div className={styles['logo']}>
                    <div className={'image-container'}>
                      <Image src={logoSecondary.url} alt="Sphere partner" width="0" height="0" sizes="100vw" priority />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles['bottom__nav']}>
              {footerNavMenu(navMenuLegal, '')}
              {legalText ? <small>{findReplaceDynamicYear(legalText)}</small> : ''}
            </div>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Footer
