import React from 'react'
import SocialMediaItem from './SocialMediaItem'
import { SocialMediaItemProps, SocialMediaRowProps } from '~types'
import Heading from '../Heading/Heading'
import styles from './SocialMediaRow.module.scss'

const SocialMediaRow = ({ align = 'left', socialMediaRowHeadline = '', socialMediaRow = {} }: SocialMediaRowProps) => {
  return (
    <>
      <div className={styles['social-icons']}>
        {socialMediaRowHeadline ? (
          <Heading level={6} isEyebrow justify={align}>
            {socialMediaRowHeadline}
          </Heading>
        ) : null}

        <ul className={styles[align == 'center' ? 'is-aligned-center' : '']}>
          {socialMediaRow.length &&
            socialMediaRow.map(
              ({ icon, platformName, url }: SocialMediaItemProps, navIndex: number) =>
                url && (
                  <li key={navIndex}>
                    <SocialMediaItem icon={icon} platformName={platformName} url={url} />
                  </li>
                )
            )}
        </ul>
      </div>
    </>
  )
}

export default SocialMediaRow
