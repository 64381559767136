interface IAuthenticate {
  username: string
  password: string
  rememberMe: boolean
}
interface ISetNewPassword {
  username: string
  password: string
  challengeName?: string
  session_code: string
  name: string
}
export const authenticate = async ({ username, password, rememberMe }: IAuthenticate) => {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/login`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        rememberMe
      }),
      credentials: 'include'
    })

    if (result.status == 200 || result.status === 401) {
      const json = await result.json()
      return await { ...json, statusCode: result.status }
    } else {
      throw new Error()
    }
  } catch (e) {
    throw new Error('Unable to login user.')
  }
}

export const setNewPassword = async ({ username, password, challengeName = 'NEW_PASSWORD_REQUIRED', session_code, name }: ISetNewPassword) => {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth-challenge`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
        session: session_code,
        challengeName,
        name
      }),
      credentials: 'include'
    })

    return await result.json()
  } catch (e) {
    throw new Error('Unable to login user.')
  }
}
