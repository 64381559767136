import { getCognitoUserSession } from 'utils/user/getCognitoUserSession'
import { LOCAL_STORAGE } from '~constants'

const Logout = () => {
  ;(async () => {
    const cognitoUser = await getCognitoUserSession()
    try {
      if (cognitoUser) {
        localStorage.removeItem(LOCAL_STORAGE.USER_KEY)
      }
    } catch (e) {
      throw e
    }
  })()
}

export default Logout
