export const LinkIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7782_6174" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="white" />
      </mask>
      <g mask="url(#mask0_7782_6174)">
        <path
          d="M8.68132 13.9571H5.31132C4.23704 13.9571 3.32132 13.5786 2.56418 12.8216C1.80704 12.0647 1.42847 11.1492 1.42847 10.0752C1.42847 9.00125 1.80704 8.08545 2.56418 7.32784C3.32132 6.57022 4.23704 6.19141 5.31132 6.19141H8.68132V7.14379H5.31132C4.50545 7.14379 3.81553 7.43077 3.24156 8.00474C2.66775 8.57855 2.38085 9.26839 2.38085 10.0743C2.38085 10.8801 2.66775 11.57 3.24156 12.1438C3.81553 12.7178 4.50545 13.0047 5.31132 13.0047H8.68132V13.9571ZM6.66656 10.5505V9.59807H13.3332V10.5505H6.66656ZM11.3185 13.9571V13.0047H14.6885C15.4943 13.0047 16.1843 12.7178 16.7582 12.1438C17.332 11.57 17.6189 10.8801 17.6189 10.0743C17.6189 9.26839 17.332 8.57855 16.7582 8.00474C16.1843 7.43077 15.4943 7.14379 14.6885 7.14379H11.3185V6.19141H14.6885C15.7628 6.19141 16.6785 6.5699 17.4356 7.32688C18.1928 8.08387 18.5713 8.99934 18.5713 10.0733C18.5713 11.1473 18.1928 12.0631 17.4356 12.8207C16.6785 13.5783 15.7628 13.9571 14.6885 13.9571H11.3185Z"
          fill="white"
        />
      </g>
    </svg>
  )
}
