import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './HeroFullScreen.module.scss'
import { ConditionalWrapper, JsonLD, Heading } from '~elements'
import { Container } from 'react-grid-system'
import { getDynamicComponents } from 'utils'
import { HeroFullScreenProps } from '~types'
import { useWindowSize } from 'utils/hooks'
import { IObserverOptions, useIsIntersecting } from 'utils/hooks/useIsIntersecting'
import { Parallax } from 'react-scroll-parallax'

const HeroFullScreen = ({
  autoplay = true,
  backgroundImage,
  backgroundVideoDesktop,
  backgroundVideoMobile,
  ctaSection,
  loopVideo = true,
  subtitle,
  title
}: HeroFullScreenProps) => {
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const { isBreakpoint: isBelowBreakpointSm } = useWindowSize('sm', '<')
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [videoPosterUrl, setVideoPosterUrl] = useState<string>('')
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const heroRef = useRef<HTMLDivElement | null>(null)
  const belowMdRootMargin = '-10% 0% -40%'
  const aboveMdRootMargin = '-15% 0% -15%'
  const [heroObserverOptions, setHeroObserverOptions] = useState<IObserverOptions>({
    root: null,
    rootMargin: isBelowBreakpointMd ? belowMdRootMargin : aboveMdRootMargin,
    threshold: [0, 1]
  })
  const intersectEntry = useIsIntersecting({ ref: heroRef, observerOptions: heroObserverOptions })

  useEffect(() => {
    const rootMargin = isBelowBreakpointMd ? belowMdRootMargin : aboveMdRootMargin
    const bgVideoUrl = isBelowBreakpointMd ? backgroundVideoMobile?.url : backgroundVideoDesktop?.url
    const bgVideoPosterUrl = isBelowBreakpointMd ? backgroundVideoMobile?.posterUrl : backgroundVideoDesktop?.posterUrl

    setHeroObserverOptions({ rootMargin })
    setVideoUrl(bgVideoUrl)
    setVideoPosterUrl(bgVideoPosterUrl)
  }, [isBelowBreakpointMd])

  useEffect(() => {
    if (videoUrl) {
      videoRef.current?.load()
    }
  }, [videoUrl])

  useEffect(() => {
    if (intersectEntry) {
      const { isIntersecting } = intersectEntry
      const isPaused = videoRef.current?.paused
      if (isIntersecting && isPaused) {
        handlePlayVideo()
      }
      if (!isIntersecting && !isPaused) {
        handlePauseVideo()
      }
    }
  }, [intersectEntry?.isIntersecting])

  const handlePlayVideo = async () => {
    try {
      await videoRef.current?.play()
    } catch (error) {
      console.error('Error:', error)

      if (error instanceof Error) {
        throw Error(error.message)
      }

      throw Error('Unknown error, please check logs.')
    }
  }

  const handlePauseVideo = () => {
    videoRef.current?.pause()
  }

  const videoStructuredData = {
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: 'Sphere in Las Vegas Marketing and Advertising Opportunities',
    description:
      'Learn about outdoor advertising opportunities at Sphere. Contact us today and have your brand on the largest out of home digital LED screen in the world.',
    thumbnailUrl: 'https://i.vimeocdn.com/video/1699741264-e9dd3e050b1a704946821675d0034bb24625b8e629bf8b401dca3ac7d5085c25-d?mw=1600&mh=900',
    uploadDate: '2023-07-19',
    contentUrl: 'https://player.vimeo.com/video/846697195'
  }

  return (
    <ConditionalWrapper
      condition={!autoplay}
      wrapper={children => (
        <Parallax opacity={[0, 3]} speed={-10} easing="easeInOut">
          {children}
        </Parallax>
      )}
    >
      <div
        className={styles['hero-full-screen']}
        {...(backgroundImage?.url ? { style: { backgroundImage: `url('${backgroundImage.url}')` } } : {})}
        ref={heroRef}
      >
        <div className={styles['hero-full-screen__inner']}>
          {autoplay ? (
            <Container className={'container container--width-100-md-down container--width-100-xl-up'}>
              <div className={cx([styles.content, styles['content--title-frame']])}>
                <div className={styles.headings}>
                  <Heading level={1} levelDisplay={isBelowBreakpointSm ? 1 : 'display'}>
                    {title}
                  </Heading>
                  {subtitle && (
                    <Heading level={2} levelDisplay={5}>
                      {subtitle}
                    </Heading>
                  )}
                </div>
                <div className={styles['ctas']}>
                  <div className={styles.button}>{getDynamicComponents(ctaSection)}</div>
                </div>
              </div>
            </Container>
          ) : (
            <Container className={'container container--width-100-md-down container--width-100-xl-up'} style={{ maxWidth: '1600px' }}>
              <div className={cx([styles['content'], styles['content--other-frame']])}>
                <div className={styles.headings}>
                  <Parallax opacity={[4, 0.2]} easing="easeInOut">
                    <Heading level={2} levelDisplay={'display'}>
                      {title}
                    </Heading>
                    {subtitle && <Heading level={3}>{subtitle}</Heading>}
                  </Parallax>
                </div>
              </div>
            </Container>
          )}
        </div>

        <div className={styles['hero-full-screen__overlay']} />

        {videoUrl || videoPosterUrl ? (
          <>
            <video
              preload={autoplay ? 'auto' : 'none'}
              autoPlay={autoplay || !videoRef.current?.paused}
              loop={loopVideo}
              muted
              playsInline={true}
              poster={isBelowBreakpointMd ? backgroundVideoMobile?.posterUrl : backgroundVideoDesktop?.posterUrl}
              ref={videoRef}
            >
              {videoUrl ? <source src={videoUrl} type="video/mp4" /> : null}
            </video>
            <JsonLD data={videoStructuredData} />
          </>
        ) : null}
      </div>
    </ConditionalWrapper>
  )
}

export default HeroFullScreen
