import { HeroProps } from '~types'
import styles from './Hero.module.scss'
import { Heading, Breadcrumbs } from '~elements'

const Hero = ({ posterImage, title, subheadline, breadcrumbs }: HeroProps) => {
  return (
    <>
      <div className={styles['hero-container--bg-img']} {...(posterImage?.url ? { style: { backgroundImage: `url('${posterImage.url}')` } } : {})} />
      <div className={styles['hero-container']}>
        <Breadcrumbs items={breadcrumbs} />
        <div className={styles['headline']}>
          {title && <Heading level={1}>{title}</Heading>}
          {subheadline && <p>{subheadline}</p>}
        </div>
      </div>
    </>
  )
}

export default Hero
