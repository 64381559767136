export const EncryptedIcon = () => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="53" height="53" rx="26.5" stroke="#036471" />
      <mask id="mask0_6177_25287" maskUnits="userSpaceOnUse" x="15" y="15" width="24" height="25">
        <rect x="15" y="15.0005" width="24" height="24" fill="white" />
      </mask>
      <g mask="url(#mask0_6177_25287)">
        <path
          d="M25.9808 29.5004H28.0193L27.4635 26.4099C27.7583 26.3074 28.0016 26.1266 28.1932 25.8676C28.3849 25.6086 28.4808 25.3196 28.4808 25.0004C28.4808 24.5914 28.3362 24.2424 28.047 23.9534C27.758 23.6642 27.409 23.5196 27 23.5196C26.591 23.5196 26.242 23.6642 25.953 23.9534C25.6638 24.2424 25.5192 24.5914 25.5192 25.0004C25.5192 25.3196 25.6151 25.6086 25.8067 25.8676C25.9984 26.1266 26.2417 26.3074 26.5365 26.4099L25.9808 29.5004ZM27 35.9619C24.991 35.3657 23.3221 34.1487 21.9933 32.3109C20.6644 30.4731 20 28.4029 20 26.1004V20.6926L27 18.0774L34 20.6926V26.1004C34 28.4029 33.3356 30.4731 32.0068 32.3109C30.6779 34.1487 29.009 35.3657 27 35.9619ZM27 34.9004C28.7333 34.3504 30.1667 33.2504 31.3 31.6004C32.4333 29.9504 33 28.1171 33 26.1004V21.3754L27 19.1446L21 21.3754V26.1004C21 28.1171 21.5667 29.9504 22.7 31.6004C23.8333 33.2504 25.2667 34.3504 27 34.9004Z"
          fill="#20FFFF"
        />
      </g>
    </svg>
  )
}
