import { ForgotFieldKeys, IField, PreviewForgotPasswordForm, PreviewLoginForm } from '~interfaces'
import { FORGOT_PASSWORD_ACTIONS, LOCAL_STORAGE, LOGIN_ACTIONS, PREVIEW_PATH, VALIDATION_ACTIONS } from '~constants'
import { Dispatch, SyntheticEvent } from 'react'
import { ForgotPassword } from '~lib/aws/cognito/services'
import { authenticate, setNewPassword } from 'services/authenticate'
import { ForgotPasswordBtnColor } from '~interfaces'

export const handleLogin = async (event: SyntheticEvent<PreviewLoginForm>, dispatch: Dispatch<any>, router: any) => {
  const { email, password, rememberMe } = event.currentTarget.elements

  try {
    const authRes = await authenticate({
      username: email.value,
      password: password.value,
      rememberMe: rememberMe.checked
    })

    if (authRes.statusCode == 401 && authRes.session_code) {
      dispatch({
        type: LOGIN_ACTIONS.SET_NEW,
        payload: {
          email: email.value,
          session_code: authRes.session_code,
          userAttributes: authRes.userAttributes
        }
      })
    } else {
      // Set a user's token based on the authRes value
      localStorage.setItem(
        LOCAL_STORAGE.USER_KEY,
        JSON.stringify({
          id: authRes.id,
          refresh: authRes.refresh
        })
      )

      const rememberMeItem = localStorage.getItem(LOCAL_STORAGE.EXO_REMEMBER_ME)

      //If user email was previously stored and the user unchecked rememberMe
      if (rememberMeItem && !rememberMe.checked) {
        //Remove the email from local storage
        localStorage.removeItem(LOCAL_STORAGE.EXO_REMEMBER_ME)
      }
      //If the user checked rememberMe, save the rememberMe item in local storage
      if (rememberMe.checked) {
        const thirtyDayExpiry = Date.now() + 1000 * 60 * 60 * 24 * 30

        const rememberMeItem = {
          email: email.value,
          expiry: thirtyDayExpiry
        }

        localStorage.setItem(LOCAL_STORAGE.EXO_REMEMBER_ME, JSON.stringify(rememberMeItem))
      }

      dispatch({
        type: LOGIN_ACTIONS.SUBMIT,
        payload: {
          email: email.value,
          password: password.value,
          rememberMe: rememberMe.checked
        }
      })

      const { next } = router.query

      if (next) {
        await router.push(next)
      } else {
        await router.push(PREVIEW_PATH)
      }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const handleInitPasswordVerification = async (event: SyntheticEvent<PreviewForgotPasswordForm>, dispatch: Dispatch<any>) => {
  const { email: emailElement } = event.currentTarget.elements
  const email = emailElement.value

  try {
    const result = await ForgotPassword.init({ Username: email })

    if (result.status === 'Success') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.INIT,
        payload: {
          email
        }
      })
    } else if (result.status === 'Error' && result.name !== 'LimitExceededException') {
      throw new Error('Failed to send verification code')
    } else if (result.status === 'Error' && result.name === 'LimitExceededException') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.INIT,
        payload: {
          email
        }
      })
    }
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.COGNITO_FAILURE,
      cognitoError: error
    })
  }
}

export const handleResendVerificationCode = async (email: string, dispatch: Dispatch<any>) => {
  try {
    const result = await ForgotPassword.init({ Username: email })

    if (result.status === 'Success') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.RESEND_CODE,
        payload: {
          email
        }
      })
    } else if (result.status === 'Error' && result.name !== 'LimitExceededException') {
      throw new Error('Failed to send verification code')
    } else if (result.status === 'Error' && result.name === 'LimitExceededException') {
      dispatch({
        type: FORGOT_PASSWORD_ACTIONS.RESEND_CODE,
        payload: {
          email,
          heading: {
            level: 1,
            title: 'You’re at max limit!',
            margin: '0 0 8px 0'
          },
          paragraph: {
            fontSize: '16px',
            justify: 'left',
            text: {
              html: `You have reached the maximum number of verification code requests. Please try again later.`
            }
          },
          submitButton: {
            text: 'Back to login',
            color: ForgotPasswordBtnColor.WHITE
          },
          fields: [],
          resendCode: false,
          resendCodeKicker: false,
          passwordConfirmed: true
        }
      })
    }
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.COGNITO_FAILURE,
      cognitoError: error
    })
  }
}

export const handleConfirmPassword = async (event: SyntheticEvent<PreviewForgotPasswordForm>, dispatch: Dispatch<any>, email: string) => {
  const { newPassword, verificationCode } = event.currentTarget.elements

  try {
    const confirmRes = await ForgotPassword.confirm({
      Username: email,
      Password: newPassword.value,
      VerificationCode: verificationCode.value
    })

    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.CONFIRM
    })

    return confirmRes
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_ACTIONS.COGNITO_FAILURE,
      cognitoError: error
    })
  }
}

export const handleInputValidation = (
  event: SyntheticEvent<PreviewForgotPasswordForm | PreviewLoginForm>,
  fields: Array<IField>,
  dispatch: Dispatch<any>
) => {
  const invalidFields = {}
  fields.forEach(field => {
    if (!field.hidden && field.validation) {
      const currentElement = event.currentTarget.elements[field.id]
      const { regex, message } = field.validation
      const currentValue = currentElement.value
      const regexTester = new RegExp(regex)
      if (field.id === ForgotFieldKeys.CONFIRM_PASSWORD && currentValue !== '') {
        const newPassword = event.currentTarget.elements[ForgotFieldKeys.NEW_PASSWORD]

        if (newPassword.value !== currentValue) {
          invalidFields[field.id] = { message, field }
        }
        if (newPassword.value === '') {
          const requiredMessage = 'Password doesn’t match!'
          invalidFields[field.id] = { message: requiredMessage, field }
        }
      } else if (field.required && currentValue === '') {
        const requiredMessage = 'This field is required!'
        invalidFields[field.id] = { message: requiredMessage, field }
      } else if (!regexTester.test(currentValue)) {
        invalidFields[field.id] = { message, field }
      }
    }
  })

  // If our invalidFields has value dispatch invalidation error.
  dispatch({
    type: VALIDATION_ACTIONS.INVALID_INPUT,
    payload: { invalidFields: { ...invalidFields }, event }
  })

  return invalidFields
}

export const handleFailedLogin = (fields: Array<IField>, dispatch: Dispatch<any>, errorMessage: string) => {
  const invalidFields = {}
  fields.forEach(field => {
    if (!field.hidden && field.validation) {
      if (field.type === 'password' || 'email') {
        invalidFields[field.id] = { message: errorMessage, field }
      }
    }
  })

  // If our invalidFields has value dispatch login error.
  dispatch({
    type: VALIDATION_ACTIONS.INVALID_INPUT,
    payload: { invalidFields: { ...invalidFields } }
  })

  return invalidFields
}

export const handleSetNewPassword = async (
  event: SyntheticEvent<PreviewLoginForm>,
  dispatch: Dispatch<any>,
  session_code: string,
  email: string,
  userAttributes: any
) => {
  const { newPassword, confirmPassword } = event.currentTarget.elements

  try {
    const name = userAttributes && userAttributes.name ? userAttributes.name : ''
    const setNewRes = await setNewPassword({ username: email, password: newPassword.value, session_code, name })

    dispatch({
      type: LOGIN_ACTIONS.SET_NEW_CONFIRM
    })

    return ''
  } catch (error) {
    console.log(error)
  }
}
